// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Routes, Route, NavigateFunction, Navigate } from "react-router-dom";

import Locations from "features/locations/components/Locations";
import Assets from "features/location/components/Location";
import AssetDetails from "features/assetdetails/components/AssetDetails";
import Licenses from "features/licenses/components/Licenses";
import LicensesFile from "features/licenses/components/LicenseFile";
import IssueDetails from "features/issuedetails/components/IssueDetails";
import Search from "features/search/components/Search";

import NotFound from "../../../../common/components/messages/NotFoundScreen/NotFoundScreen";
import IBreadcrumb from "../../../models/IBreadcrumb";

export const breadcrumbs: IBreadcrumb[] = [
  {
    from: "/locations/assets",
    to: "/locations",
    exact: true
  },
  {
    from: "/locations/assets/details",
    to: "/locations/assets",
    exact: false
  },
  {
    from: "/licenses",
    to: (navigate: NavigateFunction) => navigate(-1),
    exact: true
  },
  {
    from: "/licenses/:licenseType/:fileName",
    to: (navigate: NavigateFunction) => navigate(-1),
    exact: true
  },
  {
    from: "/issues/:issueId",
    to: (navigate: NavigateFunction) => navigate(-1),
    exact: true
  }
];

const RouterSwitch = () => {
  return (
    <Routes>
      <Route path="/licenses" element={<Licenses />} />
      <Route
        path="/licenses/:licenseType/:fileName"
        element={<LicensesFile />}
      />
      <Route path="/locations" element={<Locations />} />
      <Route path="/locations/assets" element={<Assets />} />
      <Route path="/locations/assets/details/*" element={<AssetDetails />} />
      <Route path="/issues/:issueId" element={<IssueDetails />} />
      <Route path="/search/:context" element={<Search />} />
      <Route path="/" element={<Navigate to="/locations" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouterSwitch;
