// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Box, Typography, useTheme } from "@material-ui/core";

import LocationDetails from "common/components/LocationDetails";
import getLocationDataSelector from "../selectors/getLocationDataSelector";

interface ILocationAppBarProps {
  className?: string;
  organization: string | null;
  location: string | null;
  region: string | null;
}

const LocationAppBar = ({
  className,
  organization,
  location,
  region
}: ILocationAppBarProps) => {
  const locationDataSelector = useSelector(getLocationDataSelector);
  const locationData = locationDataSelector(organization, location, region);

  const theme = useTheme(); 

  return (
    <Box className={className} display="flex" flexDirection="column">
      <Typography variant="h6" className="location-name">
        {location}
      </Typography>
      <LocationDetails
        organization={organization}
        region={region}
        assetsCount={locationData && locationData.AssetsCount}
        issuesCount={locationData && locationData.IssuesCount}
        distanceInKm={locationData && locationData.Distance}
        latitude={locationData && locationData.Latitude}
        longitude={locationData && locationData.Longitude}
        color={theme.palette.primary.main}
      />
    </Box>
  );
};

const StyledLocationAppBar = styled(LocationAppBar)`
  h6 {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default StyledLocationAppBar;
