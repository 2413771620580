// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { ComponentType, CSSProperties } from "react";

import { ListItem } from "@material-ui/core";

import IItemProps from "./models/IItemProps";

interface IRowProps {
  className?: string;
  item: any;
  ItemComponent?: ComponentType<IItemProps<any>>;
  onClick?: (data: any) => void;
  style?: CSSProperties | undefined;
}

const Row = ({ className, item, ItemComponent, onClick, style }: IRowProps) => {
  const handleClick = () => {
    if (onClick) onClick(item);
  };

  return (
    <ListItem className={className} onClick={handleClick} style={style}>
      {ItemComponent && <ItemComponent data={item} />}
    </ListItem>
  );
};

export default Row;
