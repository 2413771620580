// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Box, IconButton, Container, Button } from "@material-ui/core";

import { spacingNormal, spacingXLarge } from "core/styles/StyleVariables";

import { AppBarContext, IAppBarContext } from "./AppBar";
import CollapsibleDrawer from "./CollapsibleDrawer";
import Icon from "common/components/Icon";

import { colorGray0, colorGray100 } from "../../styles/ColorVariables";

interface ICollapsible {
  visible: boolean;
  className?: string;
  collapseButtonClientHeight?: number;
  Content?: () => JSX.Element;
  onVisibleChange?: (visible: boolean) => void;
}

const Collapsible = ({
  visible,
  className,
  Content,
  onVisibleChange
}: ICollapsible) => {
  const handleExpandClick = useCallback(() => {
    if (onVisibleChange) onVisibleChange(true);
  }, [onVisibleChange]);

  const handleCollapseClick = useCallback(() => {
    if (onVisibleChange) onVisibleChange(false);
  }, [onVisibleChange]);

  return (
    <Box className={className}>
      <Box className="expand-button" data-qa="expand-button">
        <IconButton onClick={handleExpandClick}>
          {!visible && <Icon name="expand_more" size="md" />}
        </IconButton>
      </Box>
      <Container className="collapsible-content">
        {Content ? (
          <CollapsibleDrawer
            anchor="top"
            open={visible}
            hideBackdrop={true}
            transitionDuration={300}
          >
            <Content />
          </CollapsibleDrawer>
        ) : (
          <></>
        )}
      </Container>
      <Box className={`collapse-button ${visible ? "visible" : ""}`}>
        <Button onClick={handleCollapseClick}>
          <Icon name="expand_less" size="lg" />
          <FormattedMessage id="mobile.appbar.close" defaultMessage="Close" />
        </Button>
      </Box>
    </Box>
  );
};

const StyledCollapsible = styled(Collapsible)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;

  .expand-button {
    text-align: center;

    & button {
      height: 32px;
      margin-top: -${spacingNormal};
      padding: ${spacingNormal};
      width: 100%;
    }
  }

  .collapsible-content {
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${spacingXLarge};
      right: ${spacingXLarge};
    }
  }

  .collapse-button {
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;

    background-color: ${colorGray100};
    color: ${colorGray0};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 0px;
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    visibility: hidden;
    width: 100%;

    &.visible {
      opacity: 0.8;
      height: ${(props) => props.collapseButtonClientHeight}px;
      visibility: visible;
    }

    & > button {
      color: ${colorGray0};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      & .icon {
        color: ${colorGray0};
        display: block;
        font-size: 32px;
      }

      & > span {
        display: block;
      }
    }
  }
`;

export default (props: ICollapsible) => (
  <AppBarContext.Consumer>
    {({ collapseButtonClientHeight }: IAppBarContext) => (
      <StyledCollapsible
        collapseButtonClientHeight={collapseButtonClientHeight}
        {...props}
      />
    )}
  </AppBarContext.Consumer>
);
