// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ILicenseData from "../models/ILicenseData";
import INugetLicense from "../models/INugetLicenses";
import { IntlShape } from "react-intl";

const convertNugetLicensesToData = (
  licenses: INugetLicense[],
  intl: IntlShape
): ILicenseData[] => {
  return licenses.map((license) => {
    return {
      library: license.Name,
      version: license.Version,
      licenses:
        license.LicenseFileName != null || license.LicenseUrl != null
          ? intl.formatMessage({
              id: "licenses.license",
              defaultMessage: "License"
            })
          : undefined,
      licensesUrl: null,
      licensesFileName: license.LicenseFileName ?? null,
      projectUrl: license.ProjectUrl !== "" ? license.ProjectUrl : null,
      licenseType: "nuget"
    };
  });
};

export default convertNugetLicensesToData;
