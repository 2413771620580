// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "extensions/Console";

import { setupEndpoint } from "@apm/endpoint";

import App from "core/components/App";
import StoreProvider from "core/components/StoreProvider";
import clearStorage from "core/utils/clearStorage";

setupEndpoint(clearStorage).then(() => {
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);

  root.render(
    <StoreProvider>
      <App />
    </StoreProvider>
  );
});

serviceWorkerRegistration.register();
