// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Action } from "redux";

export enum Actions {
  AppBar_SetContent = "AppBar_SetContent",
  AppBar_SetCollapsibleContent = "AppBar_SetCollapsibleContent",
  AppBar_SetBackArrowVisible = "AppBar_SetBackArrowVisible",
  AppBar_SetMoreIconVisible = "AppBar_SetMoreIconVisible"
}

export interface ISetAppBarContent extends Action<string> {
  Content?: () => JSX.Element;
}

export interface ISetAppBarCollapsibleContent extends Action<string> {
  CollapsibleContent?: () => JSX.Element;
}

export interface ISetBackArrowVisible extends Action<string> {
  value: boolean;
}

export interface ISetMoreIconVisible extends Action<string> {
  value: boolean;
}

export const setAppBarContentAction = (Content?: () => JSX.Element) => ({
  type: Actions.AppBar_SetContent,
  Content
});

export const setAppBarCollapsibleContentAction = (
  CollapsibleContent?: () => JSX.Element
) => ({
  type: Actions.AppBar_SetCollapsibleContent,
  CollapsibleContent
});

export const setBackArrowVisibleAction = (value: boolean) => ({
  type: Actions.AppBar_SetBackArrowVisible,
  value
});

export const setMoreIconVisibleAction = (value: boolean) => ({
  type: Actions.AppBar_SetMoreIconVisible,
  value
});
