// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Location } from "history";

import SearchParams from "core/models/SearchParams";

const hasRequiredQueryParams = (location: Location) => {
  const searchParams = new SearchParams(location.search);
  return (
    searchParams.has("organization") &&
    searchParams.has("location") &&
    searchParams.has("region")
  );
};

export default hasRequiredQueryParams;
