// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig } from "axios";
import React from "react";

import { getApiUrl } from "@apm/endpoint";

import Grid from "common/components/Grid";
import AssetSummary from "./AssetSummary";

const assetsUrl = "mobile/assets";

interface IAssetsGridProps {
  organization: string | null;
  location: string | null;
  region: string | null;
}

const AssetsGrid = ({ organization, location, region }: IAssetsGridProps) => {
  const requestConfig: AxiosRequestConfig = {
    url: getApiUrl(assetsUrl),
    method: "get",
    data: {
      organization: organization || "",
      location: location || "",
      region: region || "",
    },
  };

  return <Grid dataOrConfig={requestConfig} ItemComponent={AssetSummary} />;
};

export default AssetsGrid;
