// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ITenant from "core/models/ITenant";

const getTenant = (tenants: ITenant[] | null, tenantName: string | null) => {
  if (tenants === null) return undefined;
  if (tenantName === null) return undefined;

  const i = tenants.findIndex(t => t.InternalName === tenantName);
  if (i < 0) return undefined;

  return tenants[i];
};

export default getTenant;
