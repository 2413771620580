// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { IIconProps } from "../Message";

import { colorGray50 } from "core/styles/ColorVariables";
import Icon from "common/components/Icon";

const DataNotFoundIcon = styled(({ className }: IIconProps) => (
  <Icon className={className} name="info" variant="outlined" size="lg" />
))`
  color: ${colorGray50};
`;

export default DataNotFoundIcon;
