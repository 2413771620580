// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import Message, { Direction } from "./../Message";
import ApplicationErrorMessageMessages from "./ApplicationErrorMessageMessages";
import ApplicationErrorMessageIcon from "./ApplicationErrorMessageIcon";

interface IApplicationErrorMessage {
  direction?: Direction;
}

const ApplicationErrorMessage = ({ direction }: IApplicationErrorMessage) => {
  return (
    <Message
      Icon={ApplicationErrorMessageIcon}
      Message={ApplicationErrorMessageMessages}
      direction={direction}
    ></Message>
  );
};

export default ApplicationErrorMessage;
