// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { renderToStaticMarkup } from "react-dom/server";
import { IntlShape } from "react-intl";
import { Typography } from "@material-ui/core";

export interface IParameterChartTooltipProps {
  intl: IntlShape;
  date: number;
  value: number;
  name: string;
}

const ParameterChartTooltip = ({
  intl,
  date,
  name,
  value
}: IParameterChartTooltipProps) => {
  return (
    <table className="bb-tooltip">
      <tbody>
        <tr>
          <th colSpan={2}>
            <Typography>{intl.formatDate(date)}</Typography>
          </th>
        </tr>
        <tr className="bb-tooltip-name--data">
          <td className="name">
            <Typography>{name}</Typography>
          </td>
          <td className="value">
            <Typography>{intl.formatNumber(value)}</Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ParameterChartTooltip;

export const getStaticMarkup = (props: IParameterChartTooltipProps) =>
  renderToStaticMarkup(<ParameterChartTooltip {...props} />);
