// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { ComponentType, MouseEvent } from "react";
import styled from "styled-components";

import { Box, Typography } from "@material-ui/core";

import { colorGray40 } from "core/styles/ColorVariables";
import { spacingSmall } from "core/styles/StyleVariables";

interface ICountIconInfoProps {
  className?: string;
  Component?: ComponentType;
  dataQa?: string;
  disabled?: boolean;
  Icon: ComponentType;
  onClick?: () => void;
  text?: string;
}

const CountIconInfo = ({
  className,
  Component,
  dataQa,
  disabled,
  Icon,
  onClick,
  text
}: ICountIconInfoProps) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <Box
      className={`
        ${className}
        ${disabled && "disabled"}
      `}
      onClick={handleClick}
    >
      <Icon />
      {text && (
        <Typography className="text" data-qa={dataQa} variant="body2">
          {text}
        </Typography>
      )}
      {Component && <Component />}
    </Box>
  );
};

const StyledCountIconInfo = styled(CountIconInfo)`
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-weight: bold;
  }

  span,
  .icon {
    margin-right: ${spacingSmall};
  }

  &.disabled {
    .text,
    .icon {
      color: ${colorGray40};
    }
  }
`;

export default StyledCountIconInfo;
