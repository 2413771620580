// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { RefObject } from "react";

const isLastVisible = (
  ref: RefObject<HTMLDivElement>,
  triggerCount: number
) => {
  if (ref.current) {
    const lastElementList = ref.current.lastElementChild;
    if (lastElementList) {
      const visible =
        lastElementList.getBoundingClientRect().top -
          triggerCount * lastElementList.clientHeight <=
        window.innerHeight;
      return visible;
    }
    return false;
  }
  return false;
};

export default isLastVisible;
