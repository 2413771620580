// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import Icon from "common/components/Icon";
import { IIconProps } from "../Message";

import { colorStatusRed } from "../../../../core/styles/ColorVariables";

const ErrorApplicationMessageIcon = styled(({ className }: IIconProps) => (
  <Icon name="error" className={className} />
))`
  color: ${colorStatusRed};
`;

export default ErrorApplicationMessageIcon;
