// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getLicensesDataKey } from "../actions/LicensesActions";
import INpmLicenses from "../models/INpmLicenses";

const getNpmLicensesSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => data[getLicensesDataKey("npm")] as ApiResponse<INpmLicenses>
);

export default getNpmLicensesSelector;
