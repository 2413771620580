// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useMemo, useCallback, useContext } from "react";
import { useLocation } from "react-router";

import BreadcrumbStorageContext from "../contexts/BreadcrumbStorageContext";

import SearchParams from "../models/SearchParams";
import useNavigate from "./useNavigate";

const useSearchQueryParam = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbStorage = useContext(BreadcrumbStorageContext);

  const searchQuery = useMemo(() => {
    const searchParams = new SearchParams(location.search);
    return searchParams.get("search");
  }, [location]);

  const removeSearchQuery = useCallback(() => {
    const searchParams = new SearchParams(location.search);
    if (searchParams.has("search")) {
      searchParams.delete("search");
      breadcrumbStorage.popSearch(location.pathname);
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString()
        },
        {
          replace: true
        }
      );
    }
  }, [location, breadcrumbStorage, navigate]);

  return { searchQuery, removeSearchQuery };
};

export default useSearchQueryParam;
