// Copyright 2016-2023 Hitachi Energy. All rights reserved.

export default class LocaleService {
  static getBrowserLocales(): string[] {
    return [
      navigator.language.substr(0, 2),
      ...Array.from(navigator.languages.map(x => x.substr(0, 2)))
    ];
  }
}
