// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsCapacitor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 7h2v18h-2v-8H5.83a3.001 3.001 0 110-2H13V7zM26.17 17a3.001 3.001 0 100-2h-6.607l2.345-5.08-1.816-.84L16.899 16l3.193 6.92 1.816-.84L19.563 17h6.608z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsCapacitor;
