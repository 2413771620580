// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

const getAppBarBackArrowVisibleSelector = createSelector(
  (state: IRootState) => state.ui.appBar.backArrowVisible,
  backArrowVisible => backArrowVisible
);

export default getAppBarBackArrowVisibleSelector;
