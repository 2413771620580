// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const showTenantSwitchFlag = "features:show-tenant-switch";

const useFeatureToggles = () => {
  const showTenantSwitch = localStorage.getItem(showTenantSwitchFlag);

  return {
    showTenantSwitch
  };
};

export default useFeatureToggles;
