// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { Typography, Box } from "@material-ui/core";
import Icon from "common/components/Icon";

import { IItemProps } from "common/components/Grid";
import ITenant from "core/models/ITenant";

export interface ITenantGridItemData {
  selected: boolean;
  tenant: ITenant;
}

const TenantGridItem = ({
  className,
  data: { selected, tenant }
}: IItemProps<ITenantGridItemData>) => (
  <Box className={className}>
    <Typography color={selected ? "primary" : "initial"} variant="body1">
      {tenant.DisplayName}
    </Typography>
    {selected && <Icon name="check" />}
  </Box>
);

const StyledTenantGridItem = styled(TenantGridItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default StyledTenantGridItem;
