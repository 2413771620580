// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import { Typography } from "@material-ui/core";

import { AppStatuses } from "../models/AppStatuses";

interface IAppStatusProps extends React.PropsWithChildren {
  status: AppStatuses;
}

const AppStatus = ({ status, children }: IAppStatusProps) => {
  return (
    <>
      {status === AppStatuses.Authenticating && <Authenticating />}
      {status === AppStatuses.Error && <Error />}
      {status === AppStatuses.Forbidden && <Forbidden />}
      {status === AppStatuses.Loading && <Loading />}
      {status === AppStatuses.Started && React.Children.toArray(children)}
    </>
  );
};

const Authenticating = () => (
  <Typography variant="body1">Redirecting to logging page ...</Typography>
);

const Error = () => <Typography variant="body1">Application error</Typography>;

const Loading = () => <Typography variant="body1">Loading ...</Typography>;

const Forbidden = () => (
  <>
    <Typography variant="body1">Access Forbidden</Typography>
    <Typography variant="body2">
      You do not have access to this tenant or asset. Speak with the administrator of your system to do a check of your user configuration (roles and access groups)
    </Typography>
  </>
);

export default AppStatus;
