// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getAssetParametersSelector from "./getAssetParametersSelector";

const getAssetParametersStatusSelector = createSelector(
  getAssetParametersSelector,
  assetParametersSelector => (assetId: string | undefined) => {
    const assetParametersResponse = assetParametersSelector(assetId);
    return assetParametersResponse && assetParametersResponse.status;
  }
);

export default getAssetParametersStatusSelector;
