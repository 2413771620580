// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import UrgencyConverter from "common/converters/UrgencyConverter";

import { spacingLarge } from "../../../core/styles/StyleVariables";

interface IIssueUrgencyProps {
  className?: string;
  urgency: string;
}

const IssueUrgency = ({ className, urgency }: IIssueUrgencyProps) => {
  const convertedUrgency = UrgencyConverter.convertApiUrgencyToUiUrgency(
    urgency
  );

  return (
    <div className={className} data-qa="issue-urgency">
      <Typography variant="overline">
        <FormattedMessage
          defaultMessage="urgency"
          id={`mobile.issues.issue_details.urgency`}
        />
      </Typography>
      <Typography variant="body1" className="content">
        <FormattedMessage
          defaultMessage={convertedUrgency}
          id={`urgency.${convertedUrgency}`}
        />
      </Typography>
    </div>
  );
};

const styledIssueUrgency = styled(IssueUrgency)`
  .content {
    margin-bottom: ${spacingLarge};
    font-weight: bold;
  }
`;

export default styledIssueUrgency;
