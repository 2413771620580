// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import ILicenseData from "../models/ILicenseData";
import Icon from "common/components/Icon";

import * as style from "core/styles/StyleVariables";
import useNavigate from "core/hooks/useNavigate";

interface ILicensesListItemProps {
  data: ILicenseData;
  className?: string;
}

const LicensesListItem = ({ className, data }: ILicensesListItemProps) => {
  const navigate = useNavigate();
  const navigateToLicenseFile = useCallback(() => {
    navigate({
      pathname: `/licenses/${data.licenseType}/${encodeURIComponent(
        data.licensesFileName ?? ""
      )}`
    });
  }, [navigate, data]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={className}
    >
      <Box className="library-version">
        <Typography variant="body1" className="library">
          {data.library || "-"}
        </Typography>
        <Typography variant="body1">
          {data.version !== null ? (
            <FormattedMessage
              id="mobile.licenses.library_version"
              defaultMessage="v. {version} | {licenses}"
              values={{ version: data.version, licenses: data.licenses }}
            />
          ) : (
            <FormattedMessage
              id="mobile.licenses.license"
              defaultMessage="{license}"
              values={{ license: data.licenses }}
            />
          )}
        </Typography>
      </Box>
      <Box className="license-project-urls" display="flex" flexDirection="row">
        {data.licensesUrl && !data.licensesFileName && (
          <a
            target="_blank"
            href={data.licensesUrl}
            rel="noopener noreferrer"
            className="licenses-url"
          >
            <Icon name="text_snippet" variant="outlined" />
          </a>
        )}
        {!data.licensesUrl && data.licensesFileName && (
          <a
            href="#/"
            className="licenses-url"
            onClick={(e) => {
              e.preventDefault();
              navigateToLicenseFile();
            }}
          >
            <Icon name="text_snippet" variant="outlined" />
          </a>
        )}
        {data.projectUrl && (
          <a
            target="_blank"
            href={data.projectUrl}
            rel="noopener noreferrer"
            className="project-url"
          >
            <Icon name="public" variant="outlined" />
          </a>
        )}
      </Box>
    </Box>
  );
};

const StyledLicensesListItem = styled(LicensesListItem)`
  width: 100%;

  .licenses-url,
  .project-url {
    color: black;
  }

  .licenses-url,
  .project-url {
    margin-left: ${style.spacingLarge};
  }

  .library {
    font-weight: bold;
  }
`;

export default StyledLicensesListItem;
