// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { Dispatch } from "redux";
import { isEmpty } from "lodash";

import { addRecentSearchAction } from "../actions/SearchActions";
import SearchParams from "../../../core/models/SearchParams";

import IBreadcrumbStorage from "core/contexts/IBreadcrumbStorage";
import { NavigateFunction, Location } from "react-router-dom";

export default class SearchService {
  static submit(
    navigate: NavigateFunction,
    location: Location,
    search: string,
    context: string,
    breadcrumbStorage: IBreadcrumbStorage,
    dispatch?: Dispatch
  ) {
    if (isEmpty(search)) return;

    const searchParams = new SearchParams(location.search);
    const pathname = `/${decodeURIComponent(context)}`;

    breadcrumbStorage.popSearch(pathname);

    searchParams.set("search", search);

    if (dispatch) {
      dispatch(
        addRecentSearchAction({
          search,
          context
        })
      );
    }

    navigate({
      pathname: pathname,
      search: searchParams.toString()
    });
  }

  static cancel(navigate: NavigateFunction) {
    navigate(-1);
  }
}
