// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const NotFoundMessages = () => (
  <>
    <Typography className="title" variant="h5">
      <FormattedMessage
        id="mobile.not_found_screen.title"
        defaultMessage="Oh no"
      />
    </Typography>
    <Typography variant="body1">
      <FormattedMessage
        defaultMessage="The page you were looking for {doesNotExist} or {cannotBeAccessed}."
        id="mobile.not_found_screen.message"
        values={{
          doesNotExist: (
            <strong>
              <FormattedMessage
                defaultMessage="does not exist"
                id="mobile.not_found_screen.message.does_not_exist"
              />
            </strong>
          ),
          cannotBeAccessed: (
            <strong>
              <FormattedMessage
                defaultMessage="cannot be accessed"
                id="mobile.not_found_screen.message.cannot_be_accessed"
              />
            </strong>
          )
        }}
      />
    </Typography>
    <Typography variant="body1">
      <FormattedMessage
        id="mobile.not_found_screen.contact_message"
        defaultMessage={`If you think this is an error, contact your local administrator.`}
      />
    </Typography>
  </>
);

export default NotFoundMessages;
