// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Typography, Box } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

interface ILicensesAppBarProps {
  className?: string;
}

const LicensesAppBar = ({ className }: ILicensesAppBarProps) => {
  return (
    <Box className={className}>
      <Typography variant="h6">
        <FormattedMessage
          id="mobile.licenses.appbar_title"
          defaultMessage="Licenses"
        />
      </Typography>
    </Box>
  );
};

const StyledLicensesAppBar = styled(LicensesAppBar)`
  h6 {
    font-weight: bold;
  }
`;

export default StyledLicensesAppBar;
