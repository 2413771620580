// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Grid from "common/components/Grid";
import Progress from "common/components/Progress";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import useSearchComponents from "core/hooks/useSearchComponents";
import useSearchQueryParam from "core/hooks/useSearchQueryParam";

import ParametersItem from "./ParametersItem";
import getAssetParametersControlFlagsSelector from "../../selectors/getAssetParametersControlFlagsSelector";
import getAssetParametersDataSelector from "../../selectors/getAssetParametersDataSelector";
import IModelDetails from "common/models/IModelDetails";

const searchContext = "locations/assets/details/parameters";

interface IAssetParametersProps {
  assetId: string | undefined;
  className?: string;
  model: IModelDetails | null;
}

const AssetParameters = ({
  assetId,
  className,
  model
}: IAssetParametersProps) => {
  useSearchComponents(searchContext);
  const intl = useIntl();
  const { searchQuery } = useSearchQueryParam();

  const assetParametersControlFlagsSelector = useSelector(
    getAssetParametersControlFlagsSelector
  );
  const {
    hasStatusFailed,
    hasStatusLoading,
    hasStatusNotFound,
    hasStatusSucceeded
  } = assetParametersControlFlagsSelector(assetId);

  const assetParametersDataSelector = useSelector(
    getAssetParametersDataSelector
  );
  const assetParametersData = useMemo(
    () =>
      assetParametersDataSelector(
        assetId,
        model,
        intl,
        searchQuery || undefined
      ),
    [assetParametersDataSelector, assetId, intl, model, searchQuery]
  );

  return (
    <div className={`asset-parameters ${className}`}>
      {hasStatusLoading && <Progress />}
      {hasStatusSucceeded && (
        <Grid
          dataOrConfig={assetParametersData || []}
          ItemComponent={({ data }) => (
            <ParametersItem assetId={assetId} parameter={data} />
          )}
          itemStyle={{ padding: 0 }}
        />
      )}
      {hasStatusNotFound && <DataNotFoundMessage direction="column" />}
      {hasStatusFailed && <ApplicationErrorMessage direction="column" />}
    </div>
  );
};

const StyledAssetParameters = styled(AssetParameters)`
  height: 100%;
`;

export default StyledAssetParameters;
