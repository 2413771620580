// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";

import {
  Box,
  Drawer as MaterialUiDrawer,
  DrawerProps,
  IconButton
} from "@material-ui/core";
import Icon from "common/components/Icon";

import { colorTextPrimaryLight } from "core/styles/ColorVariables";
import { spacingXLarge } from "core/styles/StyleVariables";

import useDrawerData from "../hooks/useDrawerData";
import useDrawer from "../../../hooks/useDrawer";

interface IDrawerBottomProps extends DrawerProps {
  className?: string;
}

const DrawerBottom = ({
  className,
  ...materialUiDrawerProps
}: IDrawerBottomProps) => {
  const [state, setState] = useState<any>();
  const { closeDrawer } = useDrawer("bottom");
  const { Content, Header, Footer, visible } = useDrawerData("bottom");

  const handleClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  useEffect(() => {
    if (visible) setState(undefined);
  }, [visible]);

  return (
    <MaterialUiDrawer
      {...materialUiDrawerProps}
      open={visible}
      onClose={handleClose}
      anchor="bottom"
      className={className}
    >
      <Box className="header">
        {Header ? <Header state={state} setState={setState} /> : <Box />}
        <IconButton className="close-button" onClick={handleClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <Box className="content">
        {Content && <Content state={state} setState={setState} />}
      </Box>
      <Box className="footer">
        {Footer && <Footer state={state} setState={setState} />}
      </Box>
    </MaterialUiDrawer>
  );
};

const StyledDrawerBottom = styled(DrawerBottom)`
  .MuiPaper-root {
    border-radius: ${spacingXLarge} ${spacingXLarge} 0 0;
    height: 75vh;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${spacingXLarge};
    padding-bottom: ${spacingXLarge};
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);

    .close-button {
      color: ${colorTextPrimaryLight};
    }
  }

  .content {
    overflow-y: auto;
    flex-grow: 1;
  }

  .footer {
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.25);
  }
`;

export default StyledDrawerBottom;
