// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";

import Typography from "@material-ui/core/Typography";

import ParameterValue from "./ParameterValue";
import IModelDetails from "common/models/IModelDetails";
import { spacingMedium } from "core/styles/StyleVariables";

interface INameplateParameterProps {
  className?: string;
  model: IModelDetails | null;
  parameterId: string;
  parameterMessage: MessageDescriptor;
  value: string | number | null;
  icon?: JSX.Element;
}

const NameplateParameter = ({
  className,
  model,
  parameterId,
  parameterMessage,
  value,
  icon
}: INameplateParameterProps) => (
  <div className={className}>
    <Typography className="name" variant="overline" display="block">
      <FormattedMessage {...parameterMessage} />
    </Typography>

    <div className="parameter-value-wrapper">
      {icon}
      <ParameterValue
        className="value"
        model={model}
        parameterId={parameterId}
        value={value}
        variant="body1"
      />
    </div>
  </div>
);

const StyledNameplateParameter = styled(NameplateParameter)`
  & .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .value {
    font-weight: bold;
    word-break: break-word;
  }

  .parameter-value-wrapper {
    display: flex;
    align-items: center;
    column-gap: ${spacingMedium};
  }
`;

export default StyledNameplateParameter;
