// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  Actions,
  ISetAppBarContent,
  ISetAppBarCollapsibleContent,
  ISetBackArrowVisible,
  ISetMoreIconVisible
} from "../actions/AppBarActions";

export interface IState {
  appBar: {
    Content?: () => JSX.Element;
    CollapsibleContent?: () => JSX.Element;
    backArrowVisible: boolean;
    moreIconVisible: boolean;
  };
}

type AppBarAction =
  | ISetAppBarContent
  | ISetAppBarCollapsibleContent
  | ISetBackArrowVisible
  | ISetMoreIconVisible;

const reducer = (state: IState, action: AppBarAction) => {
  switch (action.type) {
    case Actions.AppBar_SetContent:
      const { Content } = action as ISetAppBarContent;
      return { ...state, appBar: { ...state.appBar, Content } };
    case Actions.AppBar_SetCollapsibleContent:
      const { CollapsibleContent } = action as ISetAppBarCollapsibleContent;
      return { ...state, appBar: { ...state.appBar, CollapsibleContent } };
    case Actions.AppBar_SetBackArrowVisible: {
      const { value } = action as ISetBackArrowVisible;
      return { ...state, appBar: { ...state.appBar, backArrowVisible: value } };
    }
    case Actions.AppBar_SetMoreIconVisible: {
      const { value } = action as ISetMoreIconVisible;
      return { ...state, appBar: { ...state.appBar, moreIconVisible: value } };
    }
    default:
      return state;
  }
};

export default reducer;
