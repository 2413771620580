// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider as ReactIntlProvider } from "react-intl";

import getTranslationsMessagesSelector from "../selectors/getTranslationsMessagesSelector";
import getLocaleSelector from "../selectors/getLocaleSelector";

const IntlProvider = ({ children }: React.PropsWithChildren) => {
  const messages = useSelector(getTranslationsMessagesSelector);
  const locale = useSelector(getLocaleSelector);
  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {React.Children.toArray(children)}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
