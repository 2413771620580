// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import RiskType from "common/models/Risk";

import {
  colorRiskLow,
  colorRiskMedium,
  colorRiskHigh,
  colorGray20
} from "core/styles/ColorVariables";

const StyledRiskDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${colorGray20};

  &.high {
    background-color: ${colorRiskHigh};
  }

  &.medium {
    background-color: ${colorRiskMedium};
  }

  &.low {
    background-color: ${colorRiskLow};
  }
`;

interface IRiskDotProps {
  risk?: RiskType;
}

const RiskDot = ({ risk }: IRiskDotProps) => {
  return (
    <StyledRiskDot
      className={`risk-dot ${risk ? risk.toLocaleLowerCase() : ""}`}
    />
  );
};

export default RiskDot;
