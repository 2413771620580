// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import Grid from "common/components/Grid";
import ITenant from "core/models/ITenant";

import TenantGridItem from "./TenantGridItem";
import useTenantSwitchContent from "../../hooks/useTenantSwitchContent";

interface ITenantSwitchHeaderProps {
  selectedTenant: ITenant;
  setSelectedTenant: (tenant: ITenant) => void;
}

const TenantSwitchHeader = ({
  selectedTenant,
  setSelectedTenant
}: ITenantSwitchHeaderProps) => {
  const { gridData, handleItemClick } = useTenantSwitchContent(
    selectedTenant,
    setSelectedTenant
  );

  return (
    <Grid
      dataOrConfig={gridData}
      ItemComponent={TenantGridItem}
      onClick={handleItemClick}
    />
  );
};

export default TenantSwitchHeader;
