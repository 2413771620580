// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import CountIconInfo from "../../CountIconInfo";
import Distance from "./Distance";
import OrganizationRegion from "./OrganizationRegion";
import Icon from "common/components/Icon";

import { spacingSmall, spacingXLarge } from "core/styles/StyleVariables";

interface ILocationDetailsProps {
  className?: string;
  organization: string | null;
  region: string | null;
  assetsCount: number | undefined | null;
  issuesCount: number | undefined | null;
  distanceInKm: number | undefined | null;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  color: string;
}

const LocationDetails = ({
  className,
  organization,
  region,
  assetsCount,
  issuesCount,
  distanceInKm,
  latitude,
  longitude,
  color
}: ILocationDetailsProps) => {
  const intl = useIntl();
  const showAssetsCount = assetsCount !== undefined && assetsCount !== null;
  const showIssuesCount = issuesCount !== undefined && issuesCount !== null;
  const showDistanceInKm = distanceInKm !== undefined && distanceInKm !== null;
  const showIcons = showAssetsCount || showIssuesCount || showDistanceInKm;

  return (
    <div className={className}>
      <OrganizationRegion organization={organization} region={region} />
      {showIcons && (
        <div className="icons">
          {showAssetsCount && (
            <CountIconInfo
              Icon={() => <Icon name="miscellaneous_services" dataQa="devices-count-icon" />}
              text={intl.formatNumber(assetsCount as number)}
              dataQa="devices-count"
            />
          )}
          {showIssuesCount && (
            <CountIconInfo
              Icon={() => <Icon name="report" variant="outlined" dataQa="issues-count-icon" />}
              text={intl.formatNumber(issuesCount as number)}
              dataQa="issues-count"
            />
          )}
          {showDistanceInKm && (
            <Distance
              distanceInKm={distanceInKm as number}
              latitude={latitude}
              longitude={longitude}
              color={color}
            />
          )}
        </div>
      )}
    </div>
  );
};

const StyledLocationDetails = styled(LocationDetails)`
  .icons {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${spacingSmall};

    > div {
      margin-right: ${spacingXLarge};
    }
  }
`;

export default StyledLocationDetails;
