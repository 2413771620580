// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedNumber } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

import BooleanFormatter from "common/formatters/Boolean";
import DateStringFormatter from "common/formatters/DateString";

import ParameterValueType from "../../models/ParameterValueType";

interface IParameterValueProps {
  className?: string;
  type: ParameterValueType;
  value: any;
  variant?: Variant | "inherit";
}

const ParameterValue = ({
  className,
  type,
  value,
  variant
}: IParameterValueProps) => {
  return type === "Bool" ? (
    <BooleanFormatter
      className={className}
      value={value as boolean}
      variant={variant}
    />
  ) : type === "Decimal" ? (
    <Typography className={className} variant={variant}>
      <FormattedNumber value={value as number} />
    </Typography>
  ) : type === "DateTime" ? (
    <DateStringFormatter
      className={className}
      value={value as string}
      variant={variant}
    />
  ) : type === "String" ? (
    <Typography className={className} variant={variant}>
      {value}
    </Typography>
  ) : null;
};

export default ParameterValue;
