// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction, removeResponseAction } from "core/actions/DataActions";

const assetDetailsUrl = "mobile/assets/summary?assetId={assetId}";
const assetParametersUrl = "mobile/assets/parametersinfo?assetId={assetId}";

const assetDetailsDataKey = "assetDetails";
const assetParametersDataKey = "assetParameters";

export const getAssetDetailsDataKey = (assetId: string) =>
  `${assetDetailsDataKey}-${assetId}`;

export const getAssetParametersDataKey = (assetId: string) =>
  `${assetParametersDataKey}-${assetId}`;

export const loadAssetDetails = (assetId: string) => {
  const url = getApiUrl(assetDetailsUrl, [{ name: "assetId", value: assetId }]);
  return loadDataAction(getAssetDetailsDataKey(assetId), endpoint.get, url);
};

export const loadAssetParameters = (assetId: string) => {
  const url = getApiUrl(assetParametersUrl, [
    { name: "assetId", value: assetId }
  ]);
  return loadDataAction(getAssetParametersDataKey(assetId), endpoint.get, url);
};

export const removeAssetDetails = (assetId: string) =>
  removeResponseAction(getAssetDetailsDataKey(assetId));

export const removeAssetParameters = (assetId: string) =>
  removeResponseAction(getAssetParametersDataKey(assetId));
