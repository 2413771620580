// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction } from "../actions/DataActions";

const environmentApiUrl = "settings/environment";

export const dataKey = "environment";

export const loadEnvironmentAction = () => {
  const url = getApiUrl(environmentApiUrl);
  return loadDataAction(dataKey, endpoint.get, url);
};
