// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import { useTheme } from "@material-ui/core";
import MaterialUiDrawer, { DrawerProps } from "@material-ui/core/Drawer";

import styled from "styled-components";

import useWindowSize from "core/hooks/useWindowSize";

import { AppBarContext, IAppBarContext } from "./AppBar";

import { spacingNormal } from "core/styles/StyleVariables";

interface ICollapsibleDrawerProps extends DrawerProps {
  collapseButtonClientHeight?: number;
  appBarClientHeight?: number;
  zIndexAppBar?: number;
  windowHeight?: number;
}

const CollapsibleDrawer = ({
  children,
  collapseButtonClientHeight,
  appBarClientHeight,
  zIndexAppBar,
  windowHeight,
  ...materialUiDrawerProps
}: ICollapsibleDrawerProps) => {
  return (
    <MaterialUiDrawer
      {...materialUiDrawerProps}
      classes={{
        paper: "appbar-drawer-paper"
      }}
    >
      {React.Children.toArray(children)}
    </MaterialUiDrawer>
  );
};

const StyledCollapsibleDrawer = styled(CollapsibleDrawer)`
  z-index: ${props => (props.zIndexAppBar || 0) - 1} !important;

  .appbar-drawer-paper {
    padding-left: ${spacingNormal};
    padding-right: ${spacingNormal};
    top: ${props => props.appBarClientHeight}px;
    height: calc(
      ${props =>
        (props.windowHeight || 0) -
        ((props.appBarClientHeight || 0) +
          (props.collapseButtonClientHeight || 0))}px
    );
  }
`;

export default (props: ICollapsibleDrawerProps) => {
  const theme = useTheme();
  const { windowHeight } = useWindowSize();
  return (
    <AppBarContext.Consumer>
      {({ appBarClientHeight, collapseButtonClientHeight }: IAppBarContext) => (
        <StyledCollapsibleDrawer
          appBarClientHeight={appBarClientHeight}
          collapseButtonClientHeight={collapseButtonClientHeight}
          zIndexAppBar={theme.zIndex.appBar}
          windowHeight={windowHeight}
          {...props}
        />
      )}
    </AppBarContext.Consumer>
  );
};
