// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { Statuses } from "@apm/endpoint";

import getLicenseTextStatusSelector from "./getLicenseTextStatusSelector";
import LicenseType from "../models/LicenseType";

const getLicenseControlFlagsSelector = createSelector(
  getLicenseTextStatusSelector,
  (licenseTextStatusSelector) => (
    licenseType: LicenseType,
    fileName: string
  ) => {
    const licenseTextStatus = licenseTextStatusSelector(licenseType, fileName);

    return {
      hasStatusLoading: licenseTextStatus === Statuses.Loading,
      hasStatusSucceeded: licenseTextStatus === Statuses.Succeeded,
      hasStatusFailed: licenseTextStatus === Statuses.Failed,
      hasStatusNotFound: licenseTextStatus === Statuses.NotFound
    };
  }
);

export default getLicenseControlFlagsSelector;
