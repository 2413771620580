// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import { Typography } from "@material-ui/core";

import IRecentSearch from "../models/IRecentSearch";

interface IRecentSearchProps {
  data: IRecentSearch;
}

const RecentSearch = ({ data }: IRecentSearchProps) => (
  <Typography>{data.search}</Typography>
);

export default RecentSearch;
