// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction } from "../actions/DataActions";

const userApiUrl = "User";

export const dataKey = "user";

export const loadUserAction = () => {
  const url = getApiUrl(userApiUrl);
  return loadDataAction(dataKey, endpoint.get, url);
};
