// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsAcVoltageSource = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83 15h3.24a7.002 7.002 0 0113.86 0h3.24a3.001 3.001 0 110 2h-3.24a7.002 7.002 0 01-13.86 0H5.83a3.001 3.001 0 110-2zm14.877-.293l-1.414-1.414L17 15.586l-2-2-3.707 3.707 1.414 1.414L15 16.414l2 2 3.707-3.707z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsAcVoltageSource;
