// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";

import { IconButton, Box } from "@material-ui/core";

import ToFunc from "../../models/ToFunc";
import Icon from "common/components/Icon";
import useNavigate from "core/hooks/useNavigate";

interface IBackToFunc {
  className?: string;
  to: ToFunc;
}

const BackToFunc = ({ className, to }: IBackToFunc) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    to(navigate);
  }, [navigate, to]);

  return (
    <Box className={className}>
      <IconButton onClick={handleClick}>
        <Icon name="chevron_left" size="md" />
      </IconButton>
    </Box>
  );
};

export default BackToFunc;
