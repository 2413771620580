// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { dataKey } from "../actions/TenantsActions";
import ITenant from "../models/ITenant";

const getTenantsSelector = createSelector(
  (state: IRootState) => state.data[dataKey],
  (tenants: ApiResponse<ITenant[]>) => tenants
);

export default getTenantsSelector;
