// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useContext } from "react";
import { useMatch } from "react-router-dom";

import { IconButton, Box } from "@material-ui/core";

import BreadcrumbStorageContext from "core/contexts/BreadcrumbStorageContext";
import Icon from "common/components/Icon";
import useNavigate from "core/hooks/useNavigate";

interface IBackToPath {
  className?: string;
  to: string;
}

const BackToPath = ({ className, to }: IBackToPath) => {
  const breadcrumbStorage = useContext(BreadcrumbStorageContext);
  const navigate = useNavigate();
  const match = useMatch(to);
  const handleClick = useCallback(() => {
    const pathname = match ? match.pathname : to;

    navigate({
      pathname,
      search: breadcrumbStorage.hasMatchingSearch(pathname)
        ? breadcrumbStorage.popSearch(pathname)
        : undefined
    });
  }, [breadcrumbStorage, navigate, match, to]);

  return (
    <Box className={className}>
      <IconButton onClick={handleClick}>
        <Icon name="chevron_left" size="md" />
      </IconButton>
    </Box>
  );
};

export default BackToPath;
