// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

import BooleanFormatter from "common/formatters/Boolean";
import DateStringFormatter from "common/formatters/DateString";
import NumberWithUnitFormatter from "common/formatters/NumberWithUnit";
import VoltageClassFormatter from "common/formatters/VoltageClass";
import isDateString from "utils/isDateString";
import isVoltageClass from "utils/isVoltageClass";

import getUnit from "../../utils/getUnit";
import ModelMessageService from "../../../../common/formatters/ModelMessage";
import IModelDetails from "common/models/IModelDetails";

interface IParameterValueProps {
  className?: string;
  model: IModelDetails | null;
  parameterId: string;
  value: any;
  variant?: Variant | "inherit";
}

const ParameterValue = ({
  className,
  model,
  parameterId,
  value,
  variant
}: IParameterValueProps) => {
  const intl = useIntl();

  const isBoolean = typeof value === "boolean";
  const isNumber = typeof value === "number";
  const isNotEmptyString = value && typeof value === "string";

  const isDateParameterShownWithoutTime =
    parameterId === "NameplateReferenceDate" ||
      parameterId === "ManufacturedDate" ||
      parameterId === "InstallDate"
      ? true
      : false;

  return isBoolean ? (
    <BooleanFormatter className={className} value={value} variant={variant} />
  ) : isNumber ? (
    <NumberWithUnitFormatter
      className={className}
      unit={getUnit(intl, parameterId)}
      value={value}
      variant={variant}
    />
  ) : isNotEmptyString && isDateString(value) ? (
    <DateStringFormatter
      className={className}
      value={value}
      variant={variant}
      showDateWithoutTime={isDateParameterShownWithoutTime}
    />
  ) : isNotEmptyString && isVoltageClass(value) ? (
    <VoltageClassFormatter
      className={className}
      value={value}
      variant={variant}
    />
  ) : isNotEmptyString ? (
    <Typography className={className} variant={variant}>
      <span>{ModelMessageService.format(intl, model, parameterId, value)}</span>
    </Typography>
  ) : (
    <Typography className={className} variant={variant}>
      -
    </Typography>
  );
};

export default ParameterValue;
