// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getAssetParametersDataKey } from "../actions/AssetDetailsActions";
import IAssetParameter from "../models/IAssetParameter";

const getAssetParametersSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (assetId: string | undefined) =>
    assetId
      ? (data[getAssetParametersDataKey(assetId)] as ApiResponse<
          IAssetParameter[]
        >)
      : undefined
);

export default getAssetParametersSelector;
