// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { Typography } from "@material-ui/core";

import AssetIcon from "common/components/AssetIcon";
import Risk from "common/models/Risk";
import { spacingLarge } from "core/styles/StyleVariables";

export interface IAssetDetailsAppBarContentProps {
  assetId: string | undefined;
  assetType: string | null | undefined;
  className?: string;
  risk: Risk | undefined;
}

const AssetDetailsAppBarContent = ({
  assetId,
  assetType,
  className,
  risk
}: IAssetDetailsAppBarContentProps) => (
  <div className={className}>
    <AssetIcon risk={risk} type={assetType} />
    <Typography variant="h6">{assetId}</Typography>
  </div>
);

const StyledAssetDetailsAppBarContent = styled(AssetDetailsAppBarContent)`
  display: flex;
  align-items: center;

  h6 {
    font-weight: bold;
    margin-left: ${spacingLarge};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default StyledAssetDetailsAppBarContent;
