// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedDate } from "react-intl";
import styled from "styled-components";

import { Box, Typography } from "@material-ui/core";

import ActionPlanStatus from "./Status";

import IIssue from "features/assetdetails/models/IIssue";

import { spacingSmall } from "core/styles/StyleVariables";

interface IActionPlanItemProps {
  className?: string;
  issue: IIssue;
}

const ActionPlanItem = ({
  className,
  issue: { ConditionText, CreateDate, HeaderText, Status }
}: IActionPlanItemProps) => {
  return (
    <Box className={className}>
      <Box className="action-plan-row">
        <ActionPlanStatus status={Status} />
        <Typography variant="body2" className="date">
          <FormattedDate value={CreateDate} />
        </Typography>
      </Box>
      <Box className="action-plan-row">
        <Typography variant="body1" className="header">
          {HeaderText}
        </Typography>
      </Box>
      <Box className="action-plan-row">
        <Typography variant="body2" className="description">
          {ConditionText}
        </Typography>
      </Box>
    </Box>
  );
};

const StyledActionPlanItem = styled(ActionPlanItem)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .MuiBox-root {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .action-plan-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacingSmall};

    .header,
    .date {
      font-weight: bold;
    }
    .description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
`;

export default StyledActionPlanItem;
