// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getLicensesDataKey } from "../actions/LicensesActions";
import INugetLicenses from "../models/INugetLicenses";

const getNugetLicensesSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => data[getLicensesDataKey("nuget")] as ApiResponse<INugetLicenses[]>
);

export default getNugetLicensesSelector;
