// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import {
  createTheme,
  MuiThemeProvider,
  ThemeOptions
} from "@material-ui/core/styles";

import GlobalStyles from "./GlobalStyle";
import {
  colorGray0,
  colorPrimaryLight,
  colorPrimary,
  colorPrimaryDark,
  colorGray100
} from "../styles/ColorVariables";
import { fontFamily } from "../styles/StyleVariables";

const muiThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: colorPrimaryLight,
      main: colorPrimary,
      dark: colorPrimaryDark,
      contrastText: colorGray0
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: 21
      },
      outlined: {
        borderColor: colorGray100,
        borderRadius: 21,
        color: colorGray100
      }
    }
  },
  typography: {
    button: {
      fontWeight: "bold",
      textTransform: "inherit"
    },
    fontFamily: fontFamily
  }
};

const muiTheme = createTheme(muiThemeOptions);

const MaterialUiProvider = ({ children }: React.PropsWithChildren) => (
  <MuiThemeProvider theme={muiTheme}>
    <GlobalStyles />
    {React.Children.toArray(children)}
  </MuiThemeProvider>
);

export default MaterialUiProvider;
