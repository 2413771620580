// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import {
  useCallback,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  useContext
} from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Box, InputBase, IconButton } from "@material-ui/core";

import Icon from "common/components/Icon";
import Link from "common/components/Link";

import SearchService from "../services/SearchService";
import BreadcrumbStorageContext from "core/contexts/BreadcrumbStorageContext";
import useSearchQueryParam from "core/hooks/useSearchQueryParam";

import { colorGray30 } from "core/styles/ColorVariables";
import {
  spacingXLarge,
  spacingMedium,
  spacingLarge,
  spacingNormal
} from "core/styles/StyleVariables";
import useNavigate from "core/hooks/useNavigate";

interface ISearchAppBarProps {
  context: string;
  className?: string;
}

const SearchAppBar = ({ className, context }: ISearchAppBarProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const { searchQuery } = useSearchQueryParam();
  const search = useRef(searchQuery || "");
  const breadcrumbStorage = useContext(BreadcrumbStorageContext);

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      search.current = e.target.value;
    },
    []
  );

  const handleSubmitClick = useCallback(() => {
    SearchService.submit(
      navigate,
      location,
      search.current,
      context,
      breadcrumbStorage,
      dispatch
    );
  }, [navigate, location, context, breadcrumbStorage, dispatch]);

  const handleCancelClick = useCallback(() => {
    SearchService.cancel(navigate);
  }, [navigate]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter")
        SearchService.submit(
          navigate,
          location,
          search.current,
          context,
          breadcrumbStorage,
          dispatch
        );
    },
    [navigate, location, context, breadcrumbStorage, dispatch]
  );

  return (
    <Box className={className}>
      <Box>
        <InputBase
          fullWidth={true}
          placeholder={intl.formatMessage({
            defaultMessage: "Search",
            id: "mobile.search"
          })}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          inputProps={{
            maxLength: 32,
            "data-qa": "search-input"
          }}
          defaultValue={search.current}
        />
        <IconButton onClick={handleSubmitClick}>
          <Icon name="search" dataQa="search-bar-button" />
        </IconButton>
      </Box>
      <Link
        onClick={handleCancelClick}
        className="cancel"
        message={{
          defaultMessage: "Cancel",
          id: "mobile.cancel"
        }}
      />
    </Box>
  );
};

const StyledSearchAppBar = styled(SearchAppBar)`
  display: flex;
  align-items: center;
  padding-top: ${spacingNormal};
  padding-bottom: ${spacingNormal};

  .MuiBox-root {
    border: 1px solid ${colorGray30};
    border-radius: 25px;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .MuiInputBase-root {
    padding-left: ${spacingXLarge};
    padding-right: ${spacingMedium};
  }
  .MuiIconButton-root {
    padding: 0;
    margin-right: ${spacingMedium};
  }
  .cancel {
    padding-left: ${spacingLarge};
    padding-right: ${spacingLarge};
  }
`;

export default StyledSearchAppBar;
