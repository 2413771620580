// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { spacingXLarge } from "core/styles/StyleVariables";
import NameplateParameter from "../NameplateParameter";
import IAssetNameplate from "../../models/IAssetNameplate";
import IModelDetails from "common/models/IModelDetails";
import { useIntl } from "react-intl";

interface IAssetNameplateItemsProps {
  className?: string;
  nameplate: IAssetNameplate;
}

const AssetNameplateItems = ({
  className,
  nameplate
}: IAssetNameplateItemsProps) => {
  const nameplateEntries = Object.entries(nameplate).filter(
    (e) => e[0] !== "Attributes" && e[0] !== "ModelImplementationId"
  );
  const attributesEntries = Object.entries(nameplate.Attributes);
  const model: IModelDetails = {
    modelId: nameplate.ModelId || "",
    implementationId: nameplate.ModelImplementationId || ""
  };

  const { formatMessage } = useIntl();

  return (
    <div className={className} data-qa="asset-nameplate-items">
      {nameplateEntries.map((e) => (
        <NameplateParameter
          className="item"
          key={e[0]}
          model={model}
          parameterId={e[0]}
          parameterMessage={{
            defaultMessage: e[0],
            id: `mobile.asset_details.nameplate.${e[0]}`
          }}
          value={
            e[0] === "LifecycleStatus"
              ? formatMessage({
                  id: `mobile.asset_details.nameplate.${e[0]}.${e[1]}`,
                  defaultMessage: e[1]
                })
              : e[1]
          }
        />
      ))}
      {attributesEntries.map((e) => (
        <NameplateParameter
          className="item"
          key={e[0]}
          model={model}
          parameterId={e[0]}
          parameterMessage={{
            defaultMessage: e[0],
            id: e[0]
          }}
          value={e[1]}
        />
      ))}
    </div>
  );
};

const StyledAssetNameplateItems = styled(AssetNameplateItems)`
  & .item {
    margin-bottom: ${spacingXLarge};
  }
`;

export default StyledAssetNameplateItems;
