// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useIntl } from "react-intl";
import styled from "styled-components";

import { RatingService } from "common/formatters/Rating";
import {
  colorGray20,
  colorGray30,
  colorStatusPurple
} from "core/styles/ColorVariables";
import IAssetDetails from "features/assetdetails/models/IAssetDetails";
import NameplateParameter from "../NameplateParameter";
import IModelDetails from "common/models/IModelDetails";
import Icon from "common/components/Icon";
import { spacingLarge } from "core/styles/StyleVariables";
interface IDeviceInfoProps {
  assetDetails: IAssetDetails;
  className?: string;
}

const DeviceInfo = ({ assetDetails, className }: IDeviceInfoProps) => {
  const { Nameplate, Age } = assetDetails;

  const intl = useIntl();
  const nameplate = Nameplate;
  const model: IModelDetails | null = nameplate
    ? {
        modelId: nameplate.ModelId || "",
        implementationId: nameplate.ModelImplementationId || ""
      }
    : null;

  return (
    <div className={`device-info ${className}`}>
      <div className="device-image">
        <Icon
          name="miscellaneous_services"
          className="device-icon"
          variant="round"
        />
      </div>
      <NameplateParameter
        className="serial-number"
        model={model}
        parameterId="SerialNumber"
        parameterMessage={{
          defaultMessage: "SerialNumber",
          id: "mobile.asset_details.nameplate.SerialNumber"
        }}
        value={nameplate && nameplate.SerialNumber}
      />
      <NameplateParameter
        className="rating"
        model={model}
        parameterId="Rating"
        parameterMessage={{
          defaultMessage: "Rating",
          id: "mobile.asset_details.nameplate.Rating"
        }}
        value={RatingService.format(
          nameplate && nameplate.Voltage,
          nameplate && nameplate.RatedPower,
          intl
        )}
      />
      <NameplateParameter
        className="model"
        model={model}
        parameterId="ManufacturerAndModel"
        parameterMessage={{
          defaultMessage: "Manufacturer / Model",
          id: "mobile.asset_details.nameplate.ManufacturerAndModel"
        }}
        value={intl.formatMessage(
          {
            defaultMessage: "{manufacturer} / {model}",
            id: "mobile.asset_details.nameplate.ManufacturerAndModel.value"
          },
          {
            manufacturer: nameplate && nameplate.Manufacturer,
            model: nameplate && nameplate.ManufacturerModel
          }
        )}
      />
      <NameplateParameter
        className="asset-type"
        model={model}
        parameterId="AssetType"
        parameterMessage={{
          defaultMessage: "AssetType",
          id: "mobile.asset_details.nameplate.AssetType"
        }}
        value={nameplate && nameplate.AssetType}
      />
      <NameplateParameter
        className="age"
        model={model}
        parameterId="Age"
        parameterMessage={{
          defaultMessage: "Age",
          id: "mobile.asset_details.nameplate.Age"
        }}
        value={Age}
      />
      <NameplateParameter
        className="subtype"
        model={model}
        parameterId="Subtype"
        parameterMessage={{
          defaultMessage: "Asset SubType",
          id: "mobile.asset_details.nameplate.AssetSubType"
        }}
        value={nameplate && nameplate.AssetSubType}
      />
      <NameplateParameter
        className="status"
        model={model}
        parameterId="Status"
        parameterMessage={{
          defaultMessage: "Status",
          id: "mobile.asset_details.nameplate.Status"
        }}
        value={
          nameplate && nameplate?.Status
            ? nameplate?.Status
            : intl.formatMessage({
                id: `mobile.asset_details.nameplate.LifecycleStatus.${nameplate?.LifecycleStatus}`,
                defaultMessage: nameplate?.LifecycleStatus
              })
        }
        icon={
          nameplate?.LifecycleStatus === "InReserve" ? (
            <Icon
              name="inventory_2"
              variant="outlined"
              className="in-reserve-icon"
            />
          ) : undefined
        }
      />
    </div>
  );
};

const StyledDeviceInfo = styled(DeviceInfo)`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: ${spacingLarge};

  .device-image {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / 2;
    grid-row: 1 / 4;

    .icon.device-icon {
      background-color: white;
      border: solid 1px ${colorGray20};
      color: ${colorGray30};
      font-size: 72px;
      border-radius: 50%;
      box-sizing: border-box;
      width: 74px;
      height: 74px;
    }
  }

  .serial-number {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .rating {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .model {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .asset-type {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    margin-left: 30%;
  }

  .subtype {
    grid-column: 1 / 2;
    grid-row: 5 / 6;
    margin-left: 30%;
  }

  .age {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  .in-reserve-icon {
    color: ${colorStatusPurple};
  }
`;

export default StyledDeviceInfo;
