// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import ILicenseData from "../models/ILicenseData";
import INpmLicenses from "../models/INpmLicenses";

const convertNpmLicensesToData = (licenses: INpmLicenses): ILicenseData[] => {
  const keys = Object.keys(licenses);
  return keys.map((key) => {
    const repositoryUrl =
      licenses[key].repository !== undefined
        ? licenses[key].repository
            .replace(/^git\+ssh:\/\/git@/, "https://")
            .replace(/^git\+/, "")
        : "";

    const packageName = licenses[key].packageName;
    const fileName = licenses[key].fileName;
    const packageVersion = licenses[key].packageVersion;

    return {
      library: packageName,
      version: packageVersion,
      licenses:
        licenses[key].licenses === undefined
          ? undefined
          : typeof licenses[key].licenses === "string"
          ? licenses[key].licenses
          : (licenses[key].licenses as string[]).join(", "),
      licensesUrl: `/static/licenses/npm/${fileName}.txt`,
      licensesFileName: null,
      projectUrl: repositoryUrl !== "" ? repositoryUrl : null,
      licenseType: "npm"
    };
  });
};

export default convertNpmLicensesToData;
