// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  setAppBarContentAction,
  setAppBarCollapsibleContentAction,
  setBackArrowVisibleAction,
  setMoreIconVisibleAction
} from "../actions/AppBarActions";

const useAppBar = () => {
  const dispatch = useDispatch();
  const setAppBarContent = useCallback(
    (Content?: () => JSX.Element) => dispatch(setAppBarContentAction(Content)),
    [dispatch]
  );
  const setAppBarCollapsibleContent = useCallback(
    (CollapsibleContent?: () => JSX.Element) =>
      dispatch(setAppBarCollapsibleContentAction(CollapsibleContent)),
    [dispatch]
  );
  const setAppBarBackArrowVisible = useCallback(
    (visible: boolean) => {
      dispatch(setBackArrowVisibleAction(visible));
    },
    [dispatch]
  );
  const setMoreIconVisible = useCallback(
    (visible: boolean) => {
      dispatch(setMoreIconVisibleAction(visible));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      setAppBarContent(undefined);
      setAppBarCollapsibleContent(undefined);
      setAppBarBackArrowVisible(true);
      setMoreIconVisible(true);
    };
  }, [
    setAppBarContent,
    setAppBarCollapsibleContent,
    setAppBarBackArrowVisible,
    setMoreIconVisible
  ]);

  return {
    setAppBarContent,
    setAppBarCollapsibleContent,
    setAppBarBackArrowVisible,
    setMoreIconVisible
  };
};

export default useAppBar;
