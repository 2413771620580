// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape, useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IBooleanProps {
  className?: string;
  value: boolean | null;
  variant?: Variant | "inherit";
}

const Boolean = ({ className, value, variant }: IBooleanProps) => {
  const intl = useIntl();

  return (
    <Typography className={className} data-qa="boolean" variant={variant}>
      {BooleanService.format(value, intl)}
    </Typography>
  );
};

export class BooleanService {
  public static format(value: boolean | null, intl: IntlShape) {
    const textValue = value ? "true" : "false";

    return intl.formatMessage({
      defaultMessage: textValue,
      id: `mobile.formatter.true_false.${textValue}`
    });
  }
}

export default Boolean;
