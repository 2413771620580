// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import CircularProgress from "@material-ui/core/CircularProgress";

interface IProgressProps {
  className?: string;
  position?: "center";
}

const Progress = ({ className, position = "center" }: IProgressProps) => (
  <div
    className={`
      progress
      ${className}
      ${position}
    `}
  >
    <CircularProgress />
  </div>
);

const StyledProgress = styled(Progress)`
  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`;

export default StyledProgress;
