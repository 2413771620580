// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { ListItem, Typography, List } from "@material-ui/core";
import styled from "styled-components";

import SubscoreName from "common/formatters/SubscoreName";

import { arrowWidth } from "common/components/Accordion";
import { colorGray20 } from "core/styles/ColorVariables";
import IModelDetails from "common/models/IModelDetails";
import NumericalValue from "common/formatters/NumericalValue";

interface ISubscores {
  className?: string;
  model: IModelDetails | null;
  subscores: { [name: string]: number };
}

const Subscores = ({ className, model, subscores }: ISubscores) => {
  return (
    <List className={`subscores ${className}`} data-qa="asset-subscores">
      {Object.entries(subscores).map(([subscoreName, value]) => (
        <ListItem className="subscore-item" key={subscoreName} disableGutters>
          <Typography variant="body2" className="name">
            <SubscoreName model={model} nameId={subscoreName} />
          </Typography>
          <Typography variant="h6" className="value">
            <NumericalValue value={value} roundValue={false}/>
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

const StyledSubscores = styled(Subscores)`
  .subscore-item {
    padding-left: ${arrowWidth};

    &:last-child {
      border-bottom: 1px solid ${colorGray20};
    }
  }
`;

export default StyledSubscores;
