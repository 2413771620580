// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { useIntl, IntlShape } from "react-intl";

export const minimumFractionDigits = 1;
export const maximumFractionDigits = 3;

interface INumericalValueProps {
  value: number;
  roundValue?: boolean; 
}

const NumericalValue = ({ value, roundValue = true }: INumericalValueProps) => {
  const intl = useIntl();
  const formattedValue = roundValue ? NumericalValueService.format(value, intl) : NumericalValueService.formatWithoutRound(value, intl);
  return <span>{formattedValue}</span>;
};

export class NumericalValueService {
  static format(value: number, intl: IntlShape) {
    const formatter = new NumericalValueFormatter();
    return formatter.format(value, intl);
  }

  static formatWithoutRound(value: number, intl: IntlShape) {
    const formatter = new NumericalValueFormatter();
    return formatter.formatWithoutRound(value, intl);
  }
}

export class NumericalValueFormatter {
  private placeholder: string;
  constructor(placeholder: string = "") {
    this.placeholder = placeholder;
  }

  format(value: number, intl: IntlShape): string {
    return NumericalValueFormatter.hasValue(value)
      ? intl.formatNumber(NumericalValueFormatter.round(value), {
          minimumFractionDigits: minimumFractionDigits,
        })
      : this.placeholder;
  }

  formatWithoutRound(value: number, intl: IntlShape): string {
    return NumericalValueFormatter.hasValue(value)
      ? intl.formatNumber(value, {
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits
        })
      : this.placeholder;
  }

  private static hasValue(value: number): boolean {
    return value !== undefined && value != null;
  }
  private static round(value: number): number {
    return Math.round(value * 10) / 10;
  }
}

export default NumericalValue;
