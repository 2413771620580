// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape, useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

import isVoltageClass from "utils/isVoltageClass";

interface IVoltageClassProps {
  className?: string;
  value: string | null;
  variant?: Variant | "inherit";
}

const VoltageClass = ({ className, value, variant }: IVoltageClassProps) => {
  const intl = useIntl();

  return (
    <Typography className={className} data-qa="voltage-class" variant={variant}>
      {VoltageClassService.format(value, intl)}
    </Typography>
  );
};

export class VoltageClassService {
  public static format(value: string | null, intl: IntlShape) {
    return isVoltageClass(value)
      ? intl.formatMessage({
          defaultMessage: value as string,
          id: `mobile.constants.voltage_classes.${value}`
        })
      : "-";
  }
}

export default VoltageClass;
