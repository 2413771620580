// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { LocationDescriptorObject } from "history";

const pathToLocation = (to: string) => {
  const i = to.indexOf("?");
  const hasSearch = i >= 0;
  const path = hasSearch ? to.substr(0, i) : to;
  const search = hasSearch ? to.substr(i + 1) : "";
  const location: LocationDescriptorObject = {
    pathname: path,
    search: search
  };
  return location;
};

export default pathToLocation;
