// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { Statuses } from "@apm/endpoint";

import getAssetParametersStatusSelector from "./getAssetParametersStatusSelector";

const getAssetParametersControlFlagsSelector = createSelector(
  getAssetParametersStatusSelector,
  (assetParametersStatusSelector) => (assetId: string | undefined) => {
    const assetParametersStatus = assetParametersStatusSelector(assetId);
    return {
      hasStatusLoading: assetParametersStatus === Statuses.Loading,
      hasStatusSucceeded: assetParametersStatus === Statuses.Succeeded,
      hasStatusFailed: assetParametersStatus === Statuses.Failed,
      hasStatusNotFound: assetParametersStatus === Statuses.NotFound,
    };
  }
);

export default getAssetParametersControlFlagsSelector;
