// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { call, put, fork, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@apm/endpoint";

import {
  Actions,
  ILoadDataAction,
  setResponseAction
} from "../actions/DataActions";

export function* loadData(action: ILoadDataAction): Generator {
  const { key, endpointServiceFn, args } = action;
  try {
    yield put(setResponseAction(key, new ApiResponse()));
    const response = yield call(endpointServiceFn, ...args);
    yield put(
      setResponseAction(key, new ApiResponse(response as AxiosResponse))
    );
  } catch (e: any) {
    yield put(setResponseAction(key, new ApiResponse(e?.response || e)));
  }
}

export function* watchLoadData() {
  yield takeEvery(Actions.Data_LoadData, loadData);
}

export default function* rootData() {
  yield fork(watchLoadData);
}
