// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ApiResponse } from "@apm/endpoint";

import {
  Actions,
  ISetResponseAction,
  IRemoveResponseAction,
} from "../actions/DataActions";
import { dataKey as translationsDataKey } from "../actions/TranslationsActions";
import { dataKey as userDataKey } from "../actions/UserActions";
import { dataKey as versionDataKey } from "../actions/VersionActions";

export interface IState {
  [key: string]: ApiResponse<any>;
}

export const defaultState: IState = {
  [translationsDataKey]: new ApiResponse(),
  [userDataKey]: new ApiResponse(),
  [versionDataKey]: new ApiResponse(),
};

type DataActions = ISetResponseAction<any> | IRemoveResponseAction;

export const reducer = (state: IState = defaultState, action: DataActions) => {
  switch (action.type) {
    case Actions.Data_SetResponse: {
      const { key, response } = action as ISetResponseAction<any>;
      return { ...state, [key]: response };
    }
    case Actions.Data_RemoveResponse: {
      const { key } = action as IRemoveResponseAction;
      delete state[key];
      return { ...state };
    }
    default:
      return state;
  }
};

export default (
  state: IState = defaultState,
  action: ISetResponseAction<any>
) => {
  state = reducer(state, action) as IState;
  return state;
};
