// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

import reducer, { IRootState } from "reducers";
import sagas from "sagas";

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<IRootState> = {
  key: "apm-store",
  storage,
  whitelist: ["app", "search"]
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const presistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  presistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

interface IStorageProviderProps extends React.PropsWithChildren {}

const StorageProvider = ({ children }: IStorageProviderProps) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {React.Children.toArray(children)}
    </PersistGate>
  </Provider>
);

export default StorageProvider;
