// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import Fuse from "fuse.js";
import { IntlShape } from "react-intl";
import { createSelector } from "reselect";

import getAssetParametersSelector from "./getAssetParametersSelector";
import IAssetParameter from "../models/IAssetParameter";
import IAssetParameterTranslated from "../models/IAssetParameterTranslated";
import ModelMessageService from "common/formatters/ModelMessage";
import IModelDetails from "common/models/IModelDetails";

const getAssetParametersDataSelector = createSelector(
  getAssetParametersSelector,
  (assetParametersSelector) => (
    assetId: string | undefined,
    model: IModelDetails | null,
    intl: IntlShape,
    search?: string
  ) => {
    const assetParametersResponse = assetParametersSelector(assetId);
    const data = assetParametersResponse
      ? assetParametersResponse.data
      : undefined;
    const translatedData = translate(data, model, intl);
    const filteredData = filter(translatedData, search);
    return filteredData;
  }
);

const translate = (
  assetParameters: Array<IAssetParameter> | null | undefined,
  model: IModelDetails | null,
  intl: IntlShape
) => {
  if (!assetParameters) return assetParameters;

  return assetParameters.map<IAssetParameterTranslated>((p) => {
    const translatedName = ModelMessageService.format(
      intl,
      model,
      "parameter",
      p.Name
    );

    return {
      ...p,
      TranslatedName: translatedName
    };
  });
};

const filter = (
  assetParameters: Array<IAssetParameterTranslated> | null | undefined,
  search?: string
) => {
  if (!assetParameters) return assetParameters;
  if (!search) return assetParameters;

  const options: Fuse.IFuseOptions<IAssetParameterTranslated> = {
    distance: 100,
    keys: ["TranslatedName"],
    shouldSort: true,
    threshold: 0.6
  };
  const fuse = new Fuse(assetParameters || [], options);
  const filteredData = fuse.search(search);
  return filteredData.map((filteredRecord) => filteredRecord.item);
};

export default getAssetParametersDataSelector;
