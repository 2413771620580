// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";

import isIOS from "utils/isIOS";

const useMaps = () => {
  const openMaps = useCallback((lat: number, lon: number) => {
    if (isIOS()) {
      window.open(`maps://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    } else {
      window.open(`https://maps.google.com/maps?daddr=${lat},${lon}&amp;ll=`);
    }
  }, []);

  return openMaps;
};

export default useMaps;
