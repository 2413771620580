// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Box, Typography, Divider } from "@material-ui/core";

import Grid from "common/components/Grid";
import RecentSearch from "./RecentSearch";
import IRecentSearch from "../models/IRecentSearch";
import getRecentSearchesSelector from "../selectors/getRecentSearchesSelector";
import SearchService from "../services/SearchService";
import BreadcrumbStorageContext from "core/contexts/BreadcrumbStorageContext";
import { spacingNormal, spacingLarge } from "core/styles/StyleVariables";
import useNavigate from "core/hooks/useNavigate";

interface IRecentSearchesProps {
  className?: string;
}

const RecentSearches = ({ className }: IRecentSearchesProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const breadcrumbStorage = useContext(BreadcrumbStorageContext);
  const recentSearches = useSelector(getRecentSearchesSelector);

  const handleRecentSearchClick = useCallback(
    ({ search, context }: IRecentSearch) => {
      SearchService.submit(
        navigate,
        location,
        search,
        context,
        breadcrumbStorage
      );
    },
    [navigate, location, breadcrumbStorage]
  );

  return (
    <Box className={className}>
      <Box>
        <Typography className="searches-header">
          <FormattedMessage
            defaultMessage="Recent searches"
            id="mobile.search.recent_searches"
          />
        </Typography>
        <Divider />
      </Box>
      <Grid
        dataOrConfig={recentSearches}
        hideEmptyDataInfo={true}
        hideRowItemDivider={true}
        ItemComponent={RecentSearch}
        onClick={handleRecentSearchClick}
      />
    </Box>
  );
};

const StyledRecentSearches = styled(RecentSearches)`
  .searches-header {
    padding: ${spacingNormal} 0 ${spacingNormal} ${spacingLarge};
    font-weight: bold;
  }
`;

export default StyledRecentSearches;
