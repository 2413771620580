// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isNil } from "lodash";
import React from "react";
import { useIntl, IntlShape } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IRatingProps {
  className?: string;
  ratedPower: number | null;
  variant?: Variant | "inherit";
  voltage: number | null;
}

const Rating = ({ className, voltage, ratedPower, variant }: IRatingProps) => {
  const intl = useIntl();
  return (
    <Typography className={className} data-qa="rating" variant={variant}>
      {RatingService.format(voltage, ratedPower, intl)}
    </Typography>
  );
};

export default Rating;

export class RatingService {
  public static format(
    voltage: number | null,
    ratedPower: number | null,
    intl: IntlShape
  ) {
    return intl.formatMessage(
      {
        defaultMessage:
          "{voltage} {voltageUnit} / {ratedPower} {ratedPowerUnit}",
        id: "mobile.formatter.rating.value"
      },
      {
        voltage: !isNil(voltage) ? intl.formatNumber(voltage) : "-",
        voltageUnit: this.getUnit(intl, "voltage"),
        ratedPower: !isNil(ratedPower) ? intl.formatNumber(ratedPower) : "-",
        ratedPowerUnit: this.getUnit(intl, "rated-power")
      }
    );
  }

  private static getUnit(intl: IntlShape, parameterId: string) {
    const hasUnit = ["rated-power", "voltage"].indexOf(parameterId) >= 0;
    return hasUnit
      ? intl.formatMessage({
          defaultMessage: "unknown",
          id: `mobile.formatter.rating.units.${parameterId}`
        })
      : null;
  }
}
