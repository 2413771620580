// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { IntlShape, useIntl } from "react-intl";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";
import isDateString from "../../utils/isDateString";

interface IDateStringProps {
  className?: string;
  value: string | null;
  variant?: Variant | "inherit";
  showDateWithoutTime?: boolean;
}

const DateString = ({
  className,
  value,
  variant,
  showDateWithoutTime = false
}: IDateStringProps) => {
  const intl = useIntl();

  return (
    <Typography className={className} data-qa="date-string" variant={variant}>
      {DateStringService.format(value, intl, showDateWithoutTime)}
    </Typography>
  );
};

export default DateString;

export class DateStringService {
  public static format(
    value: string | null,
    intl: IntlShape,
    showDateWithoutTime?: boolean
  ) {
    if (value === null) return "-";
    if (!isDateString(value)) return "-";

    const date = new Date(value);

    const isDateWithoutTime =
      (date.getUTCHours() === 0 &&
        date.getUTCMinutes() === 0 &&
        date.getUTCSeconds() === 0 &&
        date.getUTCMilliseconds() === 0) ||
      showDateWithoutTime;

    const isDateWithTimeWithoutSeconds =
      date.getUTCHours() !== 0 &&
      date.getUTCMinutes() !== 0 &&
      date.getUTCSeconds() === 0 &&
      date.getUTCMilliseconds() === 0;

    return isDateWithoutTime
      ? intl.formatDate(date, {
          year: "numeric",
          month: "numeric",
          day: "numeric"
        })
      : isDateWithTimeWithoutSeconds
      ? intl.formatDate(date, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        })
      : intl.formatDate(date, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric"
        });
  }
}
