// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";

import IconButton from "@material-ui/core/IconButton";

import useDrawer from "../../hooks/useDrawer";
import Icon from "common/components/Icon";

const MoreIconButton = () => {
  const { toggleDrawer } = useDrawer("right");
  const handleClick = useCallback(() => {
    toggleDrawer();
  }, [toggleDrawer]);

  return (
    <div data-qa="drawer-button">
      <IconButton onClick={handleClick}>
        <Icon name="more_vert" />
      </IconButton>
    </div>
  );
};

export default MoreIconButton;
