// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Container, Grid, Button } from "@material-ui/core";

import useLogout from "../hooks/useLogout";

interface IPanelHeaderProps {
  className?: string;
}

const PanelHeader = ({ className }: IPanelHeaderProps) => {
  const handleLogoutClick = useLogout();

  return (
    <Container className={className}>
      <Grid container spacing={4}>
        <Grid className="sign-out" item>
          <Button
            className="btn-outlined-dark"
            variant="outlined"
            onClick={handleLogoutClick}
          >
            <FormattedMessage
              id="mobile.sidepanel.logout"
              defaultMessage="Logout"
            />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default styled(PanelHeader)`
  padding: 1em;
`;
