// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Container } from "@material-ui/core";
import styled from "styled-components";

import useWindowSize from "core/hooks/useWindowSize";
import {
  spacingXXXXLarge,
  spacingLarge,
  spacingXXLarge,
  spacingNormal,
  spacingXXXLarge,
  spacingXLarge
} from "core/styles/StyleVariables";

export interface IIconProps {
  className?: string;
}

export interface IButtonsProps {
  className?: string;
}

interface IMessageScreenProps {
  Icon: (props: IIconProps) => JSX.Element;
  Message: () => JSX.Element;
  Buttons: (props: IButtonsProps) => JSX.Element;
  className?: string;
}

const MessageScreen = ({
  Icon,
  Message,
  Buttons,
  className
}: IMessageScreenProps) => {
  const { windowHeight } = useWindowSize();

  return (
    <Container className={className} style={{ height: windowHeight }}>
      <div className="background-icon">
        <Icon></Icon>
      </div>
      <div className="message">
        <Message></Message>
      </div>
      <div className="buttons">
        <Buttons></Buttons>
      </div>
    </Container>
  );
};

const StyledMessageScreen = styled(MessageScreen)`
  display: flex;
  flex-direction: column;
  position: relative;

  > div.message {
    flex-grow: 1;
  }

  .message {
    .title {
      font-weight: bold;
      margin-top: ${spacingXXXXLarge};
    }

    p {
      margin-top: ${spacingLarge};
    }
  }

  .buttons {
    margin-top: ${spacingXXLarge};
    margin-bottom: ${spacingXXLarge};

    button {
      width: 100%;

      .home-icon {
        margin-right: ${spacingNormal};
      }
    }
  }

  .background-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.2;

    .icon {
      font-size: 280px;
      position: absolute;
      bottom: -${spacingXXXLarge};
      left: -${spacingXLarge};
      filter: blur(7px);
    }
  }
`;

export default StyledMessageScreen;
