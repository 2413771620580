// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import UserInfo from "./UserInfo";
import Section from "./Section";
import VersionInfo from "./VersionInfo";
import LicensesButton from "./LicensesButton";
import Support from "./Support";
import Tenant from "./Tenant";
import HomepageNavigation from "./HomepageNavigation";

const PanelContent = () => (
  <>
    <UserInfo />
    <Section
      title={{
        id: "mobile.sidepanel.section.apm",
        defaultMessage: "About APM"
      }}
    >
      <VersionInfo />
      <LicensesButton />
    </Section>
    <Section
      title={{
        id: "mobile.sidepanel.section.tenant",
        defaultMessage: "Tenant"
      }}
    >
      <Tenant />
    </Section>
    <Section
      title={{
        id: "mobile.sidepanel.section.support",
        defaultMessage: "Support"
      }}
    >
      <Support />
    </Section>
    <HomepageNavigation />
  </>
);

export default PanelContent;
