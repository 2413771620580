// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { BrowserRouter } from "react-router-dom";

import RouterSwitch from "./RouterSwitch";

const RouterProvider = ({ children }: React.PropsWithChildren) => (
  <BrowserRouter>
    {React.Children.toArray(children)}
    <RouterSwitch />
  </BrowserRouter>
);

export default RouterProvider;
