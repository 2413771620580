// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { ChartOptions, Chart as IChart } from "billboard.js";
import styled from "styled-components";

import {
  colorGray20,
  colorGray40,
  colorGray60,
  colorStatusOrange
} from "core/styles/ColorVariables";
import { fontFamily, fontSizeMedium } from "core/styles/StyleVariables";

import useChart from "../hooks/useChart";
import "billboard.js/dist/billboard.css";

interface IChartProps {
  chartId?: string;
  className?: string;
  configuration: ChartOptions;
  onGenerate?: (chart: IChart | undefined) => void;
}

const Chart = ({
  chartId,
  className,
  configuration,
  onGenerate
}: IChartProps) => {
  const { containerId, containerRef } = useChart(
    configuration,
    chartId,
    onGenerate
  );

  return <div className={className} id={containerId} ref={containerRef} />;
};

const StyledChart = styled(Chart)`
  touch-action: pan-y;
  &.bb svg {
    font: ${fontSizeMedium} ${fontFamily};
    width: 100%;

    .bb-axis {
      > path {
        display: none;
      }

      .tick > line {
        display: none;
      }

      .tick > text {
        fill: ${colorGray60};
      }

      &.bb-axis-x {
        > .tick:first-of-type:not(:last-of-type) > text {
          text-anchor: start !important;
        }

        > .tick:last-of-type:not(:first-of-type) > text {
          text-anchor: end !important;
        }
      }
    }

    .bb-chart-lines {
      .bb-circles .bb-circle:last-of-type {
        stroke: currentColor;
        stroke-width: 2;
        fill: white;
      }

      .bb-line {
        stroke-width: 2px;
      }
    }

    .bb-grid {
      .bb-ygrids .bb-ygrid {
        stroke: ${colorGray20};
        stroke-dasharray: 0;
        opacity: 0.5;
      }
    }

    .bb-grid-lines {
      .bb-xgrid-line {
        > line {
          stroke: ${colorGray40};
        }
      }

      .bb-ygrid-line {
        .threshold {
          > text {
            fill: ${colorStatusOrange};
            font-weight: bold;
          }

          > line {
            stroke: ${colorStatusOrange};
            stroke-dasharray: 10;
          }
        }
      }
    }
  }
`;

export default StyledChart;
