// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig } from "axios";
import React, { useCallback, useState, useEffect } from "react";

import { getApiUrl } from "@apm/endpoint";

import Grid from "common/components/Grid";
import useGeolocation from "core/hooks/useGeolocation";
import useSearchQueryParam from "core/hooks/useSearchQueryParam";
import LocationListItem from "./LocationListItem";
import ILocationsRequest from "../models/ILocationsRequest";

const locationsApiUrl = "mobile/locations";

interface ILocationsListProps {
  className?: string;
  onTotalLocationsUpdate?: (totalLocations: number) => void;
}

const LocationsList = ({
  className,
  onTotalLocationsUpdate,
}: ILocationsListProps) => {
  const getCurrentPosition = useGeolocation();
  const { searchQuery } = useSearchQueryParam();

  const [endpointConfig, setEndpointConfig] = useState<AxiosRequestConfig>();
  const getEndpointConfig = useCallback(async () => {
    const data: ILocationsRequest = {};
    try {
      const position = await getCurrentPosition();
      if (position && position.coords.latitude) {
        data.Latitude = position.coords.latitude;
      }
      if (position && position.coords.longitude) {
        data.Longitude = position.coords.longitude;
      }
    } catch (e) {
      // Hide "User denied Geolocation" error
    }

    if (searchQuery) {
      data.Search = searchQuery;
    }

    setEndpointConfig({
      url: getApiUrl(locationsApiUrl),
      method: "get",
      data,
    });
  }, [getCurrentPosition, searchQuery]);

  useEffect(() => {
    getEndpointConfig();
  }, [getEndpointConfig]);

  return (
    <Grid
      className={className}
      dataOrConfig={endpointConfig}
      onTotalUpdate={onTotalLocationsUpdate}
      ItemComponent={LocationListItem}
    />
  );
};

export default LocationsList;
