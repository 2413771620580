// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getDataKey } from "../actions/LocationActions";
import ILocation from "../models/ILocation";

const getLocationSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (
    organization: string | null,
    location: string | null,
    region: string | null
  ) =>
    data[getDataKey(organization, location, region)] as ApiResponse<ILocation>
);

export default getLocationSelector;
