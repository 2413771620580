// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getLicensesDataKey } from "../actions/LicensesActions";
import IMavenLicenses from "../models/IMavenLicenses";

const getMavenLicensesSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => data[getLicensesDataKey("maven")] as ApiResponse<IMavenLicenses[]>
);

export default getMavenLicensesSelector;
