// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig } from "axios";
import { useState, useEffect, useCallback } from "react";

import { Statuses } from "../models/Statuses";
import IDataClient from "../services/IDataClient";
import LocalClient from "../services/LocalClient";
import RemoteClient from "../services/RemoteClient";
import isArray from "../utils/isArray";
import isAxiosRequestConfig from "../utils/isAxiosRequestConfig";

const useGrid = (
  dataOrConfig: any[] | AxiosRequestConfig | undefined,
  chunkSize: number,
  onTotalUpdate?: (total: number) => void
) => {
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [status, setStatus] = useState<Statuses>();
  const [client, setClient] = useState<IDataClient>();

  const createClient = useCallback(() => {
    const client = isArray(dataOrConfig)
      ? new LocalClient(dataOrConfig)
      : isAxiosRequestConfig(dataOrConfig)
      ? new RemoteClient(dataOrConfig)
      : null;

    if (client) setClient(client);
  }, [dataOrConfig]);

  const loadNext = useCallback(() => {
    if (client && client.canLoadNext(data.length, status)) {
      client.loadNext(data, chunkSize, setData, setTotal, setStatus);
    }
  }, [client, chunkSize, data, setData, setTotal, status, setStatus]);

  useEffect(() => {
    createClient();
  }, [createClient]);

  useEffect(() => {
    if (onTotalUpdate) onTotalUpdate(total);
  }, [onTotalUpdate, total]);

  useEffect(() => {
    if (client) client.loadFirst(chunkSize, setData, setTotal, setStatus);
  }, [client, chunkSize, setData, setTotal, setStatus]);

  return {
    client,
    loadNext,
    data,
    total,
    status
  };
};

export default useGrid;
