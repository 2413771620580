// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

const getSearchContextSelector = createSelector(
  (state: IRootState) => state.ui.search.context,
  context => context
);

export default getSearchContextSelector;
