// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

import Icon from "common/components/Icon";

import useAccordion from "../hooks/useAccordion";

import { spacingLarge } from "core/styles/StyleVariables";

export const arrowWidth = "28px";

interface IAccordionProps extends React.PropsWithChildren {
  className?: string;
  allowToExpand?: boolean;
  Content?: () => JSX.Element;
}

const Accordion = ({
  className,
  allowToExpand = true,
  children,
  Content
}: IAccordionProps) => {
  const { expanded, handleClick } = useAccordion({ allowToExpand });

  return (
    <Box className={`${className} ${expanded ? "extended" : ""}`}>
      <Box className="accordion-container" onClick={handleClick}>
        {allowToExpand && (
          <Box className="arrow" display="flex" alignItems="center">
            <Icon name="expand_more" className={expanded ? "down" : "right"} />
          </Box>
        )}
        {children}
      </Box>
      {expanded && Content && <Content />}
    </Box>
  );
};

const StyledAccordion = styled(Accordion)`
  width: 100%;
  .accordion-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .arrow {
      padding-right: ${spacingLarge};

      .icon {
        transition: transform 0.2s ease-in-out;
      }

      .down {
        transform: rotate(0deg);
      }

      .right {
        transform: rotate(-90deg);
      }
    }
  }
`;

export default StyledAccordion;
