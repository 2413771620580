// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { dataKey } from "../actions/TranslationsActions";
import ITranslations from "../models/ITranslations";

const getTranslationsSelector = createSelector(
  (state: IRootState) => state.data[dataKey],
  (response: ApiResponse<ITranslations>) => response
);

export default getTranslationsSelector;
