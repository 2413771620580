// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useSelector } from "react-redux";

import DrawerTypes from "../../../models/DrawerTypes";
import getDrawerVisibleSelector from "../../../selectors/getDrawerVisibleSelector";
import getDrawerHeaderSelector from "../../../selectors/getDrawerHeaderSelector";
import getDrawerContentSelector from "../../../selectors/getDrawerContentSelector";
import getDrawerFooterSelector from "../../../selectors/getDrawerFooterSelector";

const useDrawerData = (drawerType: DrawerTypes) => {
  const visible = useSelector(getDrawerVisibleSelector)(drawerType);
  const Header = useSelector(getDrawerHeaderSelector)(drawerType);
  const Content = useSelector(getDrawerContentSelector)(drawerType);
  const Footer = useSelector(getDrawerFooterSelector)(drawerType);

  return {
    visible,
    Header,
    Content,
    Footer
  };
};

export default useDrawerData;
