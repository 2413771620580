// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useCallback } from "react";

import { getApiUrl } from "@apm/endpoint";

import clearStorage from "core/utils/clearStorage";

const logoutApiUrl = "User/Logout?redirectTo={redirectTo}";

const useLogout = () => {
  const logout = useCallback(() => {
    clearStorage();
    const url = getApiUrl(logoutApiUrl, [
      {
        name: "redirectTo",
        value: window.location.href,
      },
    ]);
    window.location.href = url;
  }, []);

  return logout;
};

export default useLogout;
