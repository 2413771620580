// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { CSSProperties, useCallback, useMemo } from "react";
import styled from "styled-components";

import {
  iconFontSizeMedium,
  iconFontSizeSmall,
  iconFontSizeXLarge,
  iconFontSizeXXXLarge
} from "../../core/styles/StyleVariables";

interface IIconProps {
  className?: string;
  dataQa?: string;
  name: string;
  onClick?: (e: React.MouseEvent) => void;
  size?: "xs" | "sm" | "md" | "lg";
  style?: CSSProperties;
  variant?: "outlined" | "round" | "sharp" | "two-tone";
}

const Icon = ({
  className,
  dataQa,
  name,
  onClick,
  size = "md",
  style,
  variant
}: IIconProps) => {
  const materialIconsClassName = useMemo(() => {
    const variantClassName = variant
      ? `material-icons-${variant}`
      : "material-icons";
    const sizeClassName = size;
    return `${variantClassName} ${sizeClassName}`;
  }, [size, variant]);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) onClick(e);
    },
    [onClick]
  );

  return (
    <span
      className={`icon ${materialIconsClassName} ${className}`}
      data-qa={dataQa}
      onClick={handleClick}
      style={style}
    >
      {name}
    </span>
  );
};

export default styled(Icon)`
  user-select: none;

  &.xs {
    font-size: ${iconFontSizeSmall};
  }
  &.sm {
    font-size: ${iconFontSizeMedium};
  }
  &.md {
    font-size: ${iconFontSizeXLarge};
  }
  &.lg {
    font-size: ${iconFontSizeXXXLarge};
  }
`;
