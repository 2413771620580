// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import getVersionSelector from "core/selectors/getVersionSelector";
import IVersion from "core/models/IVersion";

interface IVersionInfoProps {
  version: IVersion;
}

const VersionInfo = ({
  version: { Build, Major, Minor, Sprint }
}: IVersionInfoProps) => (
  <Typography data-qa="version-info" variant="body1">
    <FormattedMessage
      id="mobile.sidepanel.version"
      defaultMessage="Version {Major}.{Minor}.{Sprint}.{Build}"
      values={{
        Major,
        Minor,
        Sprint,
        Build
      }}
    />
  </Typography>
);

export default () => {
  const version = useSelector(getVersionSelector);
  if (!isEmpty(version.data)) {
    return <VersionInfo version={version.data as IVersion} />;
  }
  return null;
};
