// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import useAppBar from "core/hooks/useAppBar";

import { loadLicenses, removeLicenses } from "../actions/LicensesActions";
import LicensesAppBar from "./LicensesAppBar";
import Layout from "core/components/Layout";
import LicensesList from "./LicensesList";
import getDataForLicensesSelector from "./../selectors/getDataForLicensesSelector";
import getLicensesControlFlagsSelector from "./../selectors/getLicensesControlFlagsSelector";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import Progress from "common/components/Progress";

const Licenses = () => {
  const intl = useIntl();
  const { setAppBarContent } = useAppBar();
  const dispatch = useDispatch();

  const dataForLicensesSelector = useSelector(getDataForLicensesSelector);
  const dataForLicenses = dataForLicensesSelector(intl);

  const licensesControlFlagSelector = useSelector(
    getLicensesControlFlagsSelector
  );

  const {
    hasStatusLoading,
    hasStatusSucceeded,
    hasStatusFailed,
    hasStatusNotFound
  } = licensesControlFlagSelector();

  useEffect(() => {
    setAppBarContent(() => <LicensesAppBar />);
  }, [setAppBarContent]);

  useEffect(() => {
    dispatch(loadLicenses("common"));
    dispatch(loadLicenses("npm"));
    dispatch(loadLicenses("nuget"));
    dispatch(loadLicenses("maven"));
    return () => {
      dispatch(removeLicenses("common"));
      dispatch(removeLicenses("npm"));
      dispatch(removeLicenses("nuget"));
      dispatch(removeLicenses("maven"));
    };
  }, [dispatch]);

  return (
    <Layout>
      {hasStatusLoading && <Progress />}
      {hasStatusSucceeded && dataForLicenses && (
        <LicensesList data={dataForLicenses}></LicensesList>
      )}
      {hasStatusNotFound && <DataNotFoundMessage direction="column" />}
      {hasStatusFailed && <ApplicationErrorMessage direction="column" />}
    </Layout>
  );
};

export default Licenses;
