// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { IRootState } from "reducers";

import { Box } from "@material-ui/core";

import useWindowSize from "core/hooks/useWindowSize";

import getSearchContextSelector from "core/selectors/getSearchContextSelector";

import AppBar from "./AppBar";
import FilterQuery from "./FilterQuery";
import SearchFabButton from "./SearchFabButton";

import { colorContentAreaLight } from "../styles/ColorVariables";
import { spacingXLarge } from "../styles/StyleVariables";

interface ILayoutProps extends React.PropsWithChildren {
  className?: string;
  searchFabButtonBottom?: string;
  context: string;
}

const Layout = ({ children, className, context }: ILayoutProps) => {
  const { windowHeight } = useWindowSize();

  return (
    <Box className={className} style={{ height: windowHeight }}>
      <AppBar>
        <FilterQuery />
      </AppBar>
      <div className="layout-content">{React.Children.toArray(children)}</div>
      {!isEmpty(context) && <SearchFabButton context={context} />}
    </Box>
  );
};

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;

  .layout-content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
  }

  .MuiToolbar-root {
    background-color: ${colorContentAreaLight};
  }

  .search {
    position: fixed;
    bottom: ${(props) => props.searchFabButtonBottom || spacingXLarge};
    right: ${spacingXLarge};
  }
`;

// Fix for "Zombie Children" issue with usage of useSelector
// https://react-redux.js.org/api/hooks#stale-props-and-zombie-children
const mapStateToProps = (state: IRootState) => ({
  context: getSearchContextSelector(state)
});

export default connect(mapStateToProps)(StyledLayout);
