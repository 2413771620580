// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

import ITenant from "../models/ITenant";

const getUserTenantSelector = createSelector(
  (state: IRootState) => state.app.userTenant,
  (userTenant: ITenant | undefined) => userTenant
);

export default getUserTenantSelector;
