// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getLicenseTextDataKey } from "../actions/LicensesActions";
import LicenseType from "../models/LicenseType";

const getLicenseTextSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (licenseType: LicenseType, fileName: string | undefined) =>
    fileName
      ? (data[getLicenseTextDataKey(licenseType, fileName)] as ApiResponse<
          string
        >)
      : undefined
);

export default getLicenseTextSelector;
