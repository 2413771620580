// Copyright 2016-2023 Hitachi Energy. All rights reserved.

const consoleError = console.error.bind(console);

console.error = (message?: any, ...args: any[]): void => {
  const messageValue: string = message.toString();
  if (messageValue.includes("Error MISSING_TRANSLATION")) {
    return;
  }

  consoleError(message, ...args);
};

export default {};
