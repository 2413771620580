// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { MessageDescriptor, FormattedMessage } from "react-intl";

import { Typography, Link as MaterialUiLink } from "@material-ui/core";

import { colorTextPrimaryLight } from "core/styles/ColorVariables";

interface ILinkProps {
  message: MessageDescriptor;
  className?: string;
  onClick?: () => void;
}

const Link = ({ message, className, onClick }: ILinkProps) => (
  <Typography className={className}>
    <MaterialUiLink onClick={onClick}>
      <FormattedMessage
        defaultMessage={message.defaultMessage}
        id={message.id}
      />
    </MaterialUiLink>
  </Typography>
);

const StyledLink = styled(Link)`
  .MuiLink-root {
    text-decoration: none;
    font-weight: bold;
    color: ${colorTextPrimaryLight};

    &:hover {
      text-decoration: none;
    }
  }
`;

export default StyledLink;
