// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { setSearchContextAction } from "../actions/SearchActions";

const useSearchComponents = (context: string) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setSearchContextAction(context));
    return () => {
      dispatch(setSearchContextAction(""));
    };
  }, [dispatch, context]);
};

export default useSearchComponents;
