// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import MessageScreen from "../MessageScreen";
import Messages from "./NotFoundMessages";
import NotFoundButtons from "./NotFoundButtons";
import NotFoundIcon from "./NotFoundIcon";

const NotFoundScreen = () => {
  return (
    <MessageScreen
      Icon={NotFoundIcon}
      Message={Messages}
      Buttons={NotFoundButtons}
    />
  );
};

export default NotFoundScreen;
