// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useEffect, useMemo } from "react";

import {
  loadNugetLicenseText,
  removeLicenseText
} from "../actions/LicensesActions";
import { spacingNormal } from "core/styles/StyleVariables";
import LicenseType from "../models/LicenseType";
import getLicenseTextSelector from "../selectors/getLicenseTextSelector";
import getLicenseTextControlFlagsSelector from "./../selectors/getLicenseTextControlFlagsSelector";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useAppBar from "core/hooks/useAppBar";
import LicensesAppBar from "./LicensesAppBar";
import Layout from "core/components/Layout";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import Progress from "common/components/Progress";
import styled from "styled-components";

interface ILicenseTextProps {
  className?: string;
}

interface ILicenseFileParams {
  licenseType: LicenseType;
  fileName: string;
}

const LicenseFile = ({ className }: ILicenseTextProps) => {
  const dispatch = useDispatch();
  const { setAppBarContent } = useAppBar();
  const { licenseType, fileName } =
    useParams() as unknown as ILicenseFileParams;

  const licenseTextSelector = useSelector(getLicenseTextSelector);
  const licenseText = useMemo(
    () => licenseTextSelector(licenseType, fileName),
    [licenseTextSelector, licenseType, fileName]
  );

  const licenseTextControlFlagsSelector = useSelector(
    getLicenseTextControlFlagsSelector
  );

  const {
    hasStatusLoading,
    hasStatusSucceeded,
    hasStatusFailed,
    hasStatusNotFound
  } = licenseTextControlFlagsSelector(licenseType, fileName);

  useEffect(() => {
    setAppBarContent(() => <LicensesAppBar />);
  }, [setAppBarContent]);

  useEffect(() => {
    if (licenseType && fileName) {
      dispatch(loadNugetLicenseText(licenseType, fileName));
    }
    return () => {
      if (licenseType && fileName) {
        dispatch(removeLicenseText(licenseType, fileName));
      }
    };
  }, [dispatch, licenseType, fileName]);

  return (
    <Layout className={className}>
      <div className="licenses-file">
        {hasStatusLoading && <Progress />}
        {hasStatusSucceeded && licenseText?.data && (
          <pre>{licenseText.data}</pre>
        )}
        {hasStatusNotFound && <DataNotFoundMessage direction="column" />}
        {hasStatusFailed && <ApplicationErrorMessage direction="column" />}
      </div>
    </Layout>
  );
};

const StyledLicenseFile = styled(LicenseFile)`
  .licenses-file {
    overflow-y: auto;
    padding: ${spacingNormal};
    box-sizing: border-box;
    height: 100%;

    pre {
      white-space: break-spaces;
      margin: 0;
    }
  }
`;

export default StyledLicenseFile;
