// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";

import Typography from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";

interface IParameterUnitProps {
  className?: string;
  unit: string;
  variant?: Variant | "inherit";
}

const ParameterUnit = ({ className, unit, variant }: IParameterUnitProps) => (
  <Typography className={className} variant={variant}>
    {unit}
  </Typography>
);

export default ParameterUnit;
