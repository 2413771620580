// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getAssetDetailsDataKey } from "../actions/AssetDetailsActions";
import IAssetDetails from "../models/IAssetDetails";

const getAssetDetailsSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (assetId: string | undefined) =>
    assetId
      ? (data[getAssetDetailsDataKey(assetId)] as ApiResponse<IAssetDetails>)
      : undefined
);

export default getAssetDetailsSelector;
