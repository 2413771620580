// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import {
  loadDataAction,
  removeResponseAction,
} from "../../../core/actions/DataActions";

const locationUrl =
  "mobile/locations/search?organization={organization}&location={location}&region={region}&latitude={latitude}&longitude={longitude}";

const dataKey = "location";

export const getDataKey = (
  organization: string | null,
  location: string | null,
  region: string | null
) => `${dataKey}-${organization}-${location}-${region}`;

export const loadLocation = (
  organization: string | null,
  location: string | null,
  region: string | null,
  latitude: number | undefined,
  longitude: number | undefined
) => {
  const url = getApiUrl(locationUrl, {
    organization: organization || "",
    location: location || "",
    region: region || "",
    latitude: latitude !== undefined ? latitude.toString() : "",
    longitude: longitude !== undefined ? longitude.toString() : "",
  });
  return loadDataAction(
    getDataKey(organization, location, region),
    endpoint.get,
    url
  );
};

export const removeLocation = (
  organization: string | null,
  location: string | null,
  region: string | null
) => removeResponseAction(getDataKey(organization, location, region));
