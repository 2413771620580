import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNumber, isString } from "lodash";
import isEqual from "core/components/RouterProvider/utils/isEqual";
import SearchParams from "core/models/SearchParams";
import getUserTenantSelector from "core/selectors/getUserTenantSelector";
import { tenantSearchParamName } from "core/services/TenantService";
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useReactRouterNavigate
} from "react-router-dom";
import pathToLocation from "core/components/RouterProvider/utils/pathToLocation";
import getTenantsSelector from "core/selectors/getTenantsSelector";
import getTenant from "core/components/RouterProvider/utils/getTenant";
import { setUserTenantAction } from "core/actions/TenantsActions";
import BreadcrumbStorageContext from "core/contexts/BreadcrumbStorageContext";

const useNavigate = () => {
  const reactRouterNavigate = useReactRouterNavigate();
  const dispatch = useDispatch();
  const locationRouter = useLocation();
  const tenants = useSelector(getTenantsSelector);
  const userTenant = useSelector(getUserTenantSelector);
  const breadcrumbStorage = useContext(BreadcrumbStorageContext);

  const isTenantUpdated = (tenant: string | null) =>
    !isEqual(tenant, userTenant?.InternalName);

  const updateBreadcrumbs = () => {
    if (breadcrumbStorage.hasSearch(locationRouter.search)) {
      breadcrumbStorage.pushSearch(
        locationRouter.pathname,
        locationRouter.search
      );
    }
  };

  const updateTenant = () => {
    const searchParams = new SearchParams(locationRouter.search);
    const tenantQueryParam = searchParams.get(tenantSearchParamName);

    if (isTenantUpdated(tenantQueryParam)) {
      const tenant = getTenant(tenants?.data, tenantQueryParam);
      dispatch(setUserTenantAction(tenant));
    }
  };

  const getLocation = (to: To) => {
    const location = isString(to) ? pathToLocation(to) : to;
    const searchParams = new SearchParams(location.search);
    const tenantQueryParam = searchParams.get(tenantSearchParamName);

    if (isTenantUpdated(tenantQueryParam)) {
      searchParams.settle(tenantSearchParamName, userTenant?.InternalName);
      location.search = searchParams.toString();
    }

    return location;
  };

  const navigate = (to: To, options?: NavigateOptions) => {
    updateBreadcrumbs();
    updateTenant();

    if (isNumber(to)) {
      reactRouterNavigate(to, options);
      return;
    }

    const location = getLocation(to);
    reactRouterNavigate(location, options);
  };

  // if tenant is updated then update url
  useEffect(() => {
    const searchParams = new SearchParams(locationRouter.search);
    const tenantQueryParam = searchParams.get(tenantSearchParamName);

    if (isTenantUpdated(tenantQueryParam)) {
      searchParams.settle(tenantSearchParamName, userTenant?.InternalName);
      locationRouter.search = searchParams.toString();
      reactRouterNavigate(locationRouter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTenant]);

  return navigate as NavigateFunction;
};

export default useNavigate;
