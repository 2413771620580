// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { Statuses } from "@apm/endpoint";

import getNpmLicensesStatusSelector from "./getNpmLicensesStatusSelector";
import getCommonLicensesStatusSelector from "./getCommonLicensesStatusSelector";
import getNugetLicensesStatusSelector from "./getNugetLicensesStatusSelector";
import getMavenLicensesStatusSelector from "./getMavenLicensesStatusSelector";

const getLicensesControlFlagsSelector = createSelector(
  getNpmLicensesStatusSelector,
  getCommonLicensesStatusSelector,
  getNugetLicensesStatusSelector,
  getMavenLicensesStatusSelector,
  (
      npmLicensesStatusSelector,
      commonLicensesStatusSelector,
      nugetLicensesStatusSelector,
      mavenLicensesStatusSelector
    ) =>
    () => {
      const npmLicensesStatus = npmLicensesStatusSelector();
      const commonLicensesStatus = commonLicensesStatusSelector();
      const nugetLicensesStatus = nugetLicensesStatusSelector();
      const mavenLicensesStatus = mavenLicensesStatusSelector();

      return {
        hasStatusLoading:
          npmLicensesStatus === Statuses.Loading ||
          commonLicensesStatus === Statuses.Loading ||
          nugetLicensesStatus === Statuses.Loading ||
          mavenLicensesStatus === Statuses.Loading,
        hasStatusSucceeded:
          npmLicensesStatus === Statuses.Succeeded &&
          commonLicensesStatus === Statuses.Succeeded &&
          nugetLicensesStatus === Statuses.Succeeded &&
          mavenLicensesStatus === Statuses.Succeeded,
        hasStatusFailed:
          npmLicensesStatus === Statuses.Failed ||
          commonLicensesStatus === Statuses.Failed ||
          nugetLicensesStatus === Statuses.Failed ||
          mavenLicensesStatus === Statuses.Failed,
        hasStatusNotFound:
          npmLicensesStatus === Statuses.NotFound ||
          commonLicensesStatus === Statuses.NotFound ||
          nugetLicensesStatus === Statuses.NotFound ||
          mavenLicensesStatus === Statuses.NotFound
      };
    }
);

export default getLicensesControlFlagsSelector;
