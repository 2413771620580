// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { isEmpty, isNil } from "lodash";

import ISearchHistory from "core/models/ISearchHistory";
import IBreadcrumbStorage from "./IBreadcrumbStorage";

export default class BreadcrumbStorage implements IBreadcrumbStorage {
  private searchHistory: ISearchHistory;

  constructor() {
    this.searchHistory = {};
  }

  hasSearch(search: string) {
    return !isEmpty(search);
  }

  hasMatchingSearch(pathname: string) {
    return !isNil(this.searchHistory[pathname]);
  }

  pushSearch(pathname: string, search: string) {
    if (this.hasMatchingSearch(pathname)) {
      this.searchHistory[pathname].push(search);
    } else {
      this.searchHistory[pathname] = [search];
    }
  }

  popSearch(pathname: string) {
    if (this.hasMatchingSearch(pathname)) {
      const search = this.searchHistory[pathname].pop() || "";

      if (this.searchHistory[pathname].length === 0) {
        delete this.searchHistory[pathname];
      }

      return search;
    }

    return "";
  }
}
