// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { combineReducers } from "redux";

import app, { IState as IAppState } from "core/reducers/AppReducer";
import ui, { IState as IUiState } from "core/reducers/UiReducer";
import data, { IState as IDataState } from "core/reducers/DataReducer";

import search, {
  IState as ISearchState
} from "features/search/reducers/SearchReducer";

export interface IRootState {
  app: IAppState;
  ui: IUiState;
  data: IDataState;
  search: ISearchState;
}

const reducer = combineReducers({
  app,
  ui,
  data,
  search
});

export default reducer;
