// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import * as React from "react";
import { useIntl, IntlShape } from "react-intl";

import { NumericalValueFormatter } from "./NumericalValue";

export interface IDegradationScoreProps {
  value: number;
}

const DegradationScore = ({ value }: IDegradationScoreProps) => {
  const intl = useIntl();
  const formattedValue = DegradationScoreService.format(value, intl);
  return <span>{formattedValue}</span>;
};

export class DegradationScoreService {
  static format(value: number, intl: IntlShape): string {
    const formatter = new NumericalValueFormatter("-");
    return formatter.format(value, intl);
  }
}

export default DegradationScore;
