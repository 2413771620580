// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig } from "axios";
import { isNil, isEmpty } from "lodash";

const isAxiosRequestConfig = (data: any): data is AxiosRequestConfig => {
  return data && !isNil(data.url) && !isEmpty(data.url);
};

export default isAxiosRequestConfig;
