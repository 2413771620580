// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsTransformer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.44 8.03l-.97-.97L13.53 6l2.03 2.03-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-2.03 2.03L12.47 24l.97-.97-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-.5-.5H5.83a3.001 3.001 0 110-2h7.11l.5-.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5zM29 18.53a3.001 3.001 0 01-2.83-2h-6.61l-.5.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5.97.97-1.06 1.06-2.03-2.03 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5L18.97 6l1.06 1.06-.97.97 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5.5.5h6.61a3.001 3.001 0 112.83 4z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsTransformer;
