// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import Typography from "@material-ui/core/Typography";

interface ILocationsAppBarContentProps {
  className?: string;
  totalLocations?: number;
}

const LocationsAppBarContent = ({
  className,
  totalLocations
}: ILocationsAppBarContentProps) => (
  <Typography className={`${className} bolded`} variant="h6">
    <FormattedMessage
      id="mobile.locations.appbar_title"
      defaultMessage="{TotalLocations} Locations"
      values={{
        TotalLocations: totalLocations || ""
      }}
    />
  </Typography>
);

const StyledLocationsAppBarContent = styled(LocationsAppBarContent)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.bolded {
    font-weight: bold;
  }
`;

export default StyledLocationsAppBarContent;
