// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getTranslationsSelector from "./getTranslationsSelector";

const getTranslationsMessagesSelector = createSelector(
  getTranslationsSelector,
  response => {
    if (response && response.data) {
      return response.data.messages;
    }

    return {};
  }
);

export default getTranslationsMessagesSelector;
