// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { List } from "@material-ui/core";

import IssueActions from "./IssueActions";
import IssueComments from "./IssueComments";

import { colorGray20 } from "core/styles/ColorVariables";
import IIssueDetails from "../models/IIssueDetails";

interface IIssueAdditionalInfoProps {
  className?: string;
  issueData: IIssueDetails;
}

const IssueAdditionalInfo = ({
  className,
  issueData,
}: IIssueAdditionalInfoProps) => {
  return (
    <List
      className={`additional-info ${className}`}
      component="div"
      data-qa="additional-info"
    >
      <IssueComments issueData={issueData} />
      <IssueActions
        workRequest={issueData.WorkRequest}
        workOrders={issueData.WorkOrders}
      />
    </List>
  );
};

const StyledIssueAdditionalInfo = styled(IssueAdditionalInfo)`
  li {
    .issue-comments,
    .issue-actions {
      width: 100%;
    }
  }

  li {
    border-bottom: solid 1px ${colorGray20};

    &:first-child {
      border-top: solid 1px ${colorGray20};
    }
  }
`;

export default StyledIssueAdditionalInfo;
