// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getAssetDetailsNameplateSelector from "./getAssetDetailsNameplateSelector";

const getAssetTypeSelector = createSelector(
  getAssetDetailsNameplateSelector,
  assetDetailsNameplateSelector => (assetId: string | undefined) => {
    const assetNameplate = assetDetailsNameplateSelector(assetId);
    return assetNameplate ? assetNameplate.AssetType : undefined;
  }
);

export default getAssetTypeSelector;
