// Copyright 2016-2023 Hitachi Energy. All rights reserved.

/* Common UX - begin */
export const colorPurple60 = "#A13FA1";

export const colorMagenta60 = "#C2347B";

export const colorBlue10 = "#E0F0FF";
export const colorBlue20 = "#C5E0FA";
export const colorBlue30 = "#9EBFF7";
export const colorBlue40 = "#77A3FC";
export const colorBlue50 = "#4C85FF";
export const colorBlue60 = "#3366FF";
export const colorBlue70 = "#2A35FF";
export const colorBlue80 = "#2C20D4";
export const colorBlue90 = "#160C9C";
export const colorBlue100 = "#080261";

export const colorTeal60 = "#3B786E";

export const colorGreen60 = "#477A43";

export const colorYellow60 = "#756E09";

export const colorGold60 = "#A65503";

export const colorOrange60 = "#D93616";

export const colorRed60 = "#E12E2E";

export const colorGray0 = "#FFFFFF";
export const colorGray10 = "#FAFAFA";
export const colorGray20 = "#DBDBDB";
export const colorGray30 = "#BABABA";
export const colorGray40 = "#9F9F9F";
export const colorGray50 = "#848484";
export const colorGray60 = "#686868";
export const colorGray70 = "#525252";
export const colorGray80 = "#3D3D3D";
export const colorGray90 = "#1F1F1F";
export const colorGray100 = "#0F0F0F";
export const colorGray110 = "#000000";

export const colorStatusYellow = "#FFD800";
export const colorStatusOrange = "#FF7300";
export const colorStatusRed = "#F03040";
export const colorStatusMagenta = "#CB2BD5";
export const colorStatusGreen = "#0CA919";
export const colorStatusBlue = "#3366FF";
export const colorStatusPurple = "#8388AD";
export const colorStatusGray = colorGray30;

/* Common UX - end */

export const colorAppBackgroundLight = colorGray10;
export const colorAppBackgroundDark = colorGray100;
export const colorChartSeries = [
  colorBlue60,
  colorTeal60,
  colorGreen60,
  colorYellow60,
  colorGold60,
  colorOrange60,
  colorRed60,
  colorPurple60,
  colorMagenta60
];
export const colorContentAreaLight = colorGray0;
export const colorContentAreaDark = colorGray90;
export const colorPrimary = colorBlue60;
export const colorPrimaryLight = colorBlue40;
export const colorPrimaryDark = colorBlue80;
export const colorTextPrimaryLight = colorGray90;
export const colorTextPrimaryDark = colorGray10;
export const colorTextSecondaryLight = colorGray60;
export const colorTextSecondaryDark = colorGray50;

export const colorRiskUnknown = colorGray40;
export const colorRiskLow = "#78B935";
export const colorRiskMedium = "#F8BD2C";
export const colorRiskHigh = "#E7453C";
export const colorFilterBarBackground = "#F5F8FF";
