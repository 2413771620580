// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { dataKey } from "../actions/UserActions";
import IUser from "../models/IUser";

const getUserSelector = createSelector(
  (state: IRootState) => state.data[dataKey],
  (response: ApiResponse<IUser>) => response
);

export default getUserSelector;
