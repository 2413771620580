// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getAssetDetailsSelector from "./getAssetDetailsSelector";

const getAssetDetailsStatusSelector = createSelector(
  getAssetDetailsSelector,
  assetDetailsSelector => (assetId: string | undefined) => {
    const assetDetails = assetDetailsSelector(assetId);
    return assetDetails && assetDetails.status;
  }
);

export default getAssetDetailsStatusSelector;
