// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Button, Container, Grid } from "@material-ui/core";

import { setUserTenantAction } from "core/actions/TenantsActions";
import useDrawer from "core/hooks/useDrawer";
import ITenant from "core/models/ITenant";
import { spacingXLarge } from "core/styles/StyleVariables";

interface ITenantSwitchFooterProps {
  className?: string;
  selectedTenant: ITenant;
}

const TenantSwitchFooter = ({
  className,
  selectedTenant
}: ITenantSwitchFooterProps) => {
  const dispatch = useDispatch();
  const bottomDrawer = useDrawer("bottom");
  const rightDrawer = useDrawer("right");

  const handleApplyClick = useCallback(() => {
    bottomDrawer.closeDrawer();
    dispatch(setUserTenantAction(selectedTenant));
  }, [bottomDrawer, dispatch, selectedTenant]);

  const handleCancelClick = useCallback(() => {
    bottomDrawer.closeDrawer();
    rightDrawer.openDrawer();
  }, [bottomDrawer, rightDrawer]);

  return (
    <Container className={className}>
      <Grid container>
        <Grid item className="buttons">
          <Button onClick={handleCancelClick}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="mobile.sidepanel.tenant_switch.cancel"
            />
          </Button>
          <Button
            disabled={!selectedTenant}
            onClick={handleApplyClick}
            variant="outlined"
          >
            <FormattedMessage
              defaultMessage="Apply"
              id="mobile.sidepanel.tenant_switch.apply"
            />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default styled(TenantSwitchFooter)`
  padding-top: ${spacingXLarge};
  padding-bottom: ${spacingXLarge};

  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;

    button {
      width: 35%;
    }
  }
`;
