// Copyright 2016-2023 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Typography } from "@material-ui/core";

import { spacingLarge } from "../../../core/styles/StyleVariables";

interface IIssueConditionProps {
  className?: string;
  condition: string;
}

const IssueCondition = ({ className, condition }: IIssueConditionProps) => {
  return (
    <div className={className} data-qa="issue-condition">
      <Typography variant="overline">
        <FormattedMessage
          defaultMessage="condition"
          id="mobile.issues.issue_details.condition"
        />
      </Typography>
      <Typography variant="body1" className="content">
        {condition}
      </Typography>
    </div>
  );
};

const styledIssueCondition = styled(IssueCondition)`
  .content {
    margin-bottom: ${spacingLarge};
    font-weight: bold;
  }
`;

export default styledIssueCondition;
